import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Space, Breadcrumb, Modal } from 'antd'
import {
  getHeaders,
  successNotification,
  errorNotification,
  clientsUrl,
  clientContactsUrl
} from '../../../config'
import Update from './Update'
import UpdateContact from './ContactUpdate'
import CreateContact from './ContactForm'
// import moment from 'moment'
import FilterTable from 'components/Forms/searchFilter'
import PageLoader from 'components/Layouts/PageLoader'

const Detail = props => {
  const [client, setClient] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    isTaxExempted: ''
  })
  const [contacts, setContacts] = useState([])

  // const [disableForm, setDisableForm] = useState(false)
  // eslint-disable-next-line
  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showContactUpdateModal, setShowContactUpdateModal] = useState(false)
  const [showContactFormModal, setShowContactFormModal] = useState(false)
  const [currentContact, setCurrentContact] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const getClient = () => {
    setLoading(true)
    axios
      .get(clientsUrl + '/' + props.match.params.id, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        setClient(res.data)
      })
      .catch(err => {
        setLoading(false)
        console.log('error')
      })
  }

  const getContacts = () => {
    axios
      .get(`${clientContactsUrl}/${props.match.params.id}/get`, {
        headers: getHeaders()
      })
      .then(res => {
        const filteredContacts = res.data.map(dep => {
          return {
            ...dep,
            createdAt: new Date(dep.createdAt).toDateString()
            // isActive: dep.isActive ? 'Active' : 'Inactive'
          }
        })
        setContacts(filteredContacts)
      })
      .catch(err => {
        console.log('error')
      })
  }

  useEffect(() => {
    getClient()
    getContacts()
    // eslint-disable-next-line
  }, [])

  const handleDetail = record => {
    setCurrentContact(record)
    setShowContactUpdateModal(true)
  }

  const handleDelete = record => {
    // setLoading(true)
    axios
      .delete(`${clientContactsUrl}/${record.id}`, {
        headers: getHeaders()
      })
      .then(res => {
        getContacts()
        successNotification('Client Contact Deleted')
      })
      .catch(err => {
        // setLoading(false)
        if (err.response) {
          // setServerErrors(err.response.data)
        }
        errorNotification('Error deleting client contact, retry.')
      })
  }

  const handleDeleteClient = () => {
    axios
      .delete(clientsUrl + '/' + props.match.params.id, {
        headers: getHeaders()
      })
      .then(res => {
        successNotification('Client Deleted')
        props.history.push('/clients')
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error deleting client, retry.')
      })
  }

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'name',
      key: 'name',
      width: 120
    },
    {
      title: 'Office Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 120
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      width: 150
      // fixed: 'left'
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <span
            onClick={handleDetail.bind(this, record)}
            style={{ color: 'blue', cursor: 'pointer' }}
          >
            View
          </span>
          <span
            onClick={handleDelete.bind(this, record)}
            style={{ color: 'red', cursor: 'pointer' }}
          >
            Delete
          </span>
          {/* eslint-disable-next-line */}
          {/* <a href="" onClick={handleDetail.bind(this, record)}>
            View
          </a> */}
        </Space>
      )
    }
  ]

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/clients">Clients</Breadcrumb.Item>
            <Breadcrumb.Item>Client Details</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="card mb-4rem">
                <div className="card-header">Client Details</div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Client Name</b>
                      </div>{' '}
                      {client.name}
                    </div>

                    <div className="col-md-5 offset-md-2 mb-2rem">
                      <div>
                        <b>Email Address</b>
                      </div>
                      {client.email}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Office Phone Number</b>
                      </div>
                      {client.phoneNumber}
                    </div>

                    <div className="col-md-5 offset-md-2 mb-2rem">
                      <div>
                        <b>Is Tax Exempted</b>
                      </div>
                      {client.isTaxExempted ? 'Yes' : 'No'}
                    </div>

                    <div className="col-md-7 offset-md-5">
                      <div>
                        <Space>
                          <Button
                            onClick={() => props.history.push('/clients')}
                          >
                            Go Back
                          </Button>
                          <Button
                            type="primary"
                            onClick={() => {
                              setShowModal(true)
                            }}
                            loading={loading}
                          >
                            Update Client
                          </Button>
                          <Button
                            type="danger"
                            onClick={() => {
                              setShowDeleteModal(true)
                            }}
                            loading={loading}
                          >
                            Delete Client
                          </Button>
                        </Space>
                      </div>
                    </div>

                    <Modal
                      title="Update Client Details"
                      centered
                      visible={showModal}
                      onOk={() => setShowModal(false)}
                      onCancel={() => setShowModal(false)}
                      footer={null}
                      width={1000}
                    >
                      <Update
                        user={client}
                        handleCancel={() => {
                          setShowModal(false)
                          getClient()
                        }}
                      />
                    </Modal>
                  </div>
                </div>
              </div>

              <div className="card" style={{ marginTop: '5rem' }}>
                <div className="card-header">
                  <div className="utils__title">
                    <span>Client Contacts</span>
                  </div>
                </div>
                <div className="card-body">
                  <div style={{ marginBottom: 16 }}>
                    <Button
                      type="primary"
                      onClick={() => setShowContactFormModal(true)}
                    >
                      Add New Contact
                    </Button>
                    {/*
                <span style={{ marginLeft: 8 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items`
                    : ""}
                </span> */}
                  </div>
                  {/* <Table
                columns={columns}
                // rowSelection={rowSelection}
                dataSource={departments}
                pagination={true}
                boardered
              /> */}

                  <FilterTable
                    dataSource={contacts}
                    columns={columns}
                    searchFields={['name', 'email', 'phoneNumber']}
                  />
                </div>
              </div>

              <Modal
                title="Add Client Contact Details"
                centered
                visible={showContactFormModal}
                onOk={() => setShowContactFormModal(false)}
                onCancel={() => setShowContactFormModal(false)}
                footer={null}
                width={1000}
              >
                <CreateContact
                  user={client.id}
                  handleCancel={() => {
                    getContacts()
                    setShowContactFormModal(false)
                  }}
                />
              </Modal>

              <Modal
                title="Update Client Contact Details"
                centered
                visible={showContactUpdateModal}
                onOk={() => setShowContactUpdateModal(false)}
                onCancel={() => setShowContactUpdateModal(false)}
                footer={null}
                width={1000}
              >
                <UpdateContact
                  user={currentContact}
                  handleCancel={() => {
                    getContacts()
                    setShowContactUpdateModal(false)
                  }}
                />
              </Modal>

              <Modal
                title="Do You Really Want to Delete This Client?"
                centered
                visible={showDeleteModal}
                onOk={() => setShowDeleteModal(false)}
                onCancel={() => setShowDeleteModal(false)}
                footer={null}
              >
                <div
                  className=""
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button
                    type="danger"
                    onClick={() => {
                      handleDeleteClient()
                    }}
                    loading={loading}
                  >
                    Delete Client
                  </Button>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </PageLoader>
  )
}

export default Detail
