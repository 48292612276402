import React, { useEffect, useState, useRef } from 'react'
import { Space, Button, Breadcrumb } from 'antd'
// import { toast } from 'react-toastify'

import { clientsUrl, getHeaders } from '../../../config'
import axios from 'axios'
import FilterTable from 'components/Forms/searchFilter'
import PageLoader from 'components/Layouts/PageLoader'

const Department = props => {
  const [departments, setDepartments] = useState([])
  const [loading, setLoading] = useState(false)
  // const [selectedRowKeys, setSelectedRowKeys] = useState([])
  // const [selectedRowData, setSelectedRowData] = useState([])

  const structureData = data => {
    const filteredDepartments = data.map(dep => {
      return {
        ...dep,
        createdAt: new Date(dep.createdAt).toDateString()
        // isActive: dep.isActive ? 'Active' : 'Inactive'
      }
    })

    return filteredDepartments
  }

  let formatUrl = url => url.replace(/http/g, 'https')

  const getDepartments = () => {
    setLoading(true)
    axios
      .get(clientsUrl, { headers: getHeaders() })
      .then(res => {
        console.log(res.data.data)
        let finalData = structureData(res.data.data)
        let existingData = localStorage.getItem('traffic.creativehubonline.com')
          ? JSON.parse(localStorage.getItem('traffic.creativehubonline.com'))
          : []
        let finalll = existingData.concat(finalData)
        localStorage.setItem(
          'traffic.creativehubonline.com',
          JSON.stringify(finalll)
        )
        console.log(
          'TOTAL',
          JSON.parse(localStorage.getItem('traffic.creativehubonline.com'))
            .length
        )

        if (res.data.next) {
          getDepartments(formatUrl(res.data.next))
        } else {
          setDepartments(
            JSON.parse(localStorage.getItem('traffic.creativehubonline.com'))
          )
          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)
        console.log('error')
      })
  }

  let clientsMounted = useRef()
  useEffect(() => {
    if (!clientsMounted.current) {
      localStorage.setItem('traffic.creativehubonline.com', JSON.stringify([]))
      getDepartments()
      clientsMounted.current = true
    }

    // eslint-disable-next-line
  }, [])

  const handleDetail = record => {
    props.history.push(`/clients/${record.id}`)
  }

  const columns = [
    {
      title: 'Client Name',
      dataIndex: 'name',
      key: 'name',
      width: 120
    },
    {
      title: 'Office Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 120
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      width: 150
      // fixed: 'left'
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, record) => (
        <Space size="middle">
          {/* <span onClick={handleDetail.bind(this, record)}>View</span> */}
          {/* eslint-disable-next-line */}
          <a href="" onClick={handleDetail.bind(this, record)}>
            View
          </a>
        </Space>
      )
    }
  ]

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row">
        <div className="col-lg-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Clients</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">
              <div className="utils__title">
                <span>Clients</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                <Button
                  type="primary"
                  onClick={() => props.history.push('/clients/add-new')}
                >
                  Add New Client
                </Button>
                {/*
                <span style={{ marginLeft: 8 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items`
                    : ""}
                </span> */}
              </div>
              {/* <Table
                columns={columns}
                // rowSelection={rowSelection}
                dataSource={departments}
                pagination={true}
                boardered
              /> */}

              <FilterTable
                dataSource={departments}
                columns={columns}
                searchFields={
                  []
                  // ['name', 'email', 'phoneNumber']
                }
              />
            </div>
          </div>
        </div>
      </div>
    </PageLoader>
  )
}

export default Department
