import { constants } from 'app-constants'

let savedUser = localStorage.getItem('mindfullAdmin')
if (!savedUser) savedUser = '{}'
const initialState = JSON.parse(savedUser)

export const authUser = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_USER:
      localStorage.setItem('mindfullAdmin', JSON.stringify(action.payload))
      return action.payload
    case constants.UNSET_USER:
      localStorage.removeItem('mindfullAdmin')
      return {}
    default:
      return state
  }
}
