import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  setProducts,
  toggleProduct,
  clearProductToggles,
  setMotorProducts,
  setHealthProducts,
  setLifeProducts,
  setPropertyProducts,
  setCompareProducts,
  clearCompareProducts,
  setPayload,
  clearPayload,
  toggleMessenger
} from 'components/Product/actions'
import { setExchangeRate } from 'components/Payments/actions'
import { setSearchItem, clearSearchItem } from 'components/Search/actions'

const WithProducts = WrappedComponent => {
  class With extends Component {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return connect(
    state => ({
      products: state.products,
      exchangeRate: state.exchangeRate,
      search: state.search,
      motorProducts: state.motorProducts,
      healthProducts: state.healthProducts,
      lifeProducts: state.lifeProducts,
      propertyProducts: state.propertyProducts,
      compareProducts: state.compareProducts,
      showMessenger: state.showMessenger,
      payload: state.payload
    }),
    dispatch => ({
      setProducts: products => dispatch(setProducts(products)),
      toggleProduct: productId => dispatch(toggleProduct(productId)),
      clearProductToggles: () => dispatch(clearProductToggles()),
      setSearchItem: search => dispatch(setSearchItem(search)),
      clearSearchItem: () => dispatch(clearSearchItem()),
      setMotorProducts: products => dispatch(setMotorProducts(products)),
      setHealthProducts: products => dispatch(setHealthProducts(products)),
      setLifeProducts: products => dispatch(setLifeProducts(products)),
      setPropertyProducts: products => dispatch(setPropertyProducts(products)),
      setCompareProducts: products => dispatch(setCompareProducts(products)),
      clearCompareProducts: () => dispatch(clearCompareProducts()),
      setPayload: payload => dispatch(setPayload(payload)),
      clearPayload: () => dispatch(clearPayload()),
      setExchangeRate: rate => dispatch(setExchangeRate(rate)),
      toggleMessenger: () => dispatch(toggleMessenger())
    })
  )(With)
}

export default WithProducts
