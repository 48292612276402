import React, { useEffect, useState } from 'react'
import { Statistic, Card } from 'antd'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import Axios from 'axios'
import { getHeaders, projectsUrl } from '../../config'
import PageLoader from 'components/Layouts/PageLoader'

const DashboardOverview = props => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)

  const getData = () => {
    setLoading(true)
    Axios.get(`${projectsUrl}/dashboard-data`, { headers: getHeaders() }).then(
      res => {
        setLoading(false)
        setData(res.data)
      }
    )
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row site-statistic-demo-card">
        <div className="col-md-3">
          <Card
            style={{ cursor: 'pointer' }}
            onClick={() => props.history.push('/clients')}
          >
            <Statistic title="Clients Achieved" value={data.clients} />
          </Card>
        </div>

        <div className="col-md-3">
          <Card
            style={{ cursor: 'pointer' }}
            onClick={() => props.history.push('/projects')}
          >
            <Statistic
              title={`All Projects (${data.allProjects})`}
              value={(data.allProjects / data.allProjects) * 100}
              precision={2}
              valueStyle={{
                color:
                  (data.allProjects / data.allProjects) * 100 < 50
                    ? '#cf1322'
                    : '#3f8600'
              }}
              prefix={
                (data.allProjects / data.allProjects) * 100 < 50 ? (
                  <ArrowDownOutlined />
                ) : (
                  <ArrowUpOutlined />
                )
              }
              suffix="%"
            />
          </Card>
        </div>

        <div className="col-md-3">
          <Card
            style={{ cursor: 'pointer' }}
            onClick={() => props.history.push('/projects')}
          >
            <Statistic
              title={`Pending Projects (${data.pendingProjects})`}
              value={(data.pendingProjects / data.allProjects) * 100}
              precision={2}
              valueStyle={{
                color:
                  (data.pendingProjects / data.allProjects) * 100 < 50
                    ? '#cf1322'
                    : '#3f8600'
              }}
              prefix={
                (data.pendingProjects / data.allProjects) * 100 < 50 ? (
                  <ArrowDownOutlined />
                ) : (
                  <ArrowUpOutlined />
                )
              }
              suffix="%"
            />
          </Card>
        </div>

        <div className="col-md-3">
          <Card
            style={{ cursor: 'pointer' }}
            onClick={() => props.history.push('/projects')}
          >
            <Statistic
              title={`Completed Projects (${data.completedProjects})`}
              value={(data.completedProjects / data.allProjects) * 100}
              precision={2}
              valueStyle={{
                color:
                  (data.completedProjects / data.allProjects) * 100 < 50
                    ? '#cf1322'
                    : '#3f8600'
              }}
              prefix={
                (data.completedProjects / data.allProjects) * 100 < 50 ? (
                  <ArrowDownOutlined />
                ) : (
                  <ArrowUpOutlined />
                )
              }
              suffix="%"
            />
          </Card>
        </div>
      </div>
    </PageLoader>
  )
}
export default DashboardOverview
