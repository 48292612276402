import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Col, Space, Form } from 'antd'
import {
  getHeaders,
  successNotification,
  errorNotification,
  clientContactsUrl
} from '../../../config'
// import { toast } from 'react-toastify'
import BasicInput from 'components/Forms/BasicInput'
// import DatePickerInput from 'components/Forms/DatePickerInput'
// import SelectInput from 'components/Forms/SelectInput'
// import moment from 'moment'
import PropTypes from 'prop-types'

const ContactForm = props => {
  const [contact, setContact] = useState({
    email: '',
    name: '',
    phoneNumber: '',
    client: props.user
  })

  // const [disableForm, setDisableForm] = useState(false)
  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)
  // const [showModal, setShowModal] = useState(false)

  const getClient = () => {
    // setContact(props.user)
  }

  useEffect(() => {
    getClient()
    // eslint-disable-next-line
  }, [])

  const handleInputChange = stateName => event => {
    setContact({ ...contact, [stateName]: event.target.value })
  }

  // const handleDateUpdate = stateName => date => {
  //   setClient({ ...client, [stateName]: date })
  // }

  // const handleSelect = stateName => value => {
  //   setClient({
  //     ...client,
  //     [stateName]: value
  //   })
  // }

  const handleSubmit = event => {
    event.preventDefault()

    setLoading(true)
    axios
      .post(`${clientContactsUrl}`, contact, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        setContact({ ...contact, email: '', name: '', phoneNumber: '' })
        props.handleCancel()

        successNotification('Client Contact Created')
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error creating client contact, retry.')
      })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="mb-1rem">
            <Form layout="vertical" className="p-2rem">
              <div className="row">
                <div className="col-md-5">
                  <BasicInput
                    label="Contact Name"
                    value={contact.name}
                    name={'contact name'}
                    placeholder="contact name"
                    handleChange={handleInputChange('name')}
                    required={true}
                  />
                  {serverErrors.name &&
                    serverErrors.name.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}
                </div>

                <div className="col-md-5 offset-md-2 offset-sm-0 ">
                  <BasicInput
                    label="Email Address"
                    value={contact.email}
                    name={'email address'}
                    placeholder="email address"
                    handleChange={handleInputChange('email')}
                    required={false}
                  />
                  {serverErrors.email &&
                    serverErrors.email.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}
                </div>

                <div className="col-md-5">
                  <BasicInput
                    label="Office Phone Number"
                    value={contact.phoneNumber}
                    name={'phone number'}
                    placeholder="phone number"
                    handleChange={handleInputChange('phoneNumber')}
                    required={false}
                  />

                  {serverErrors.phoneNumber &&
                    serverErrors.phoneNumber.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}
                </div>

                <div className="col-md-5 offset-md-2 offset-sm-0 "></div>

                {serverErrors.nonFieldErrors &&
                  serverErrors.nonFieldErrors.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}

                <Col md={24}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Space>
                      <Button onClick={() => props.handleCancel()}>
                        Close
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={loading}
                      >
                        Submit
                      </Button>
                    </Space>
                  </div>
                </Col>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

ContactForm.propTypes = {
  user: PropTypes.object,
  handleCancel: PropTypes.object
}

export default ContactForm
