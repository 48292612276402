import React from 'react'
import { Switch } from 'react-router-dom'
import PropTypes from 'prop-types'

// import PageNotFound from 'pages/PageNotFound'
import ProjectReports from './ProjectReports'
import AccountReports from './AccountReports'
import PrivateRoute from 'hocs/PrivateRoute'

const Reports = props => (
  <>
    <Switch>
      <PrivateRoute
        exact
        path={`${props.match.path}/projects`}
        component={ProjectReports}
      />
      <PrivateRoute
        exact
        path={`${props.match.path}/accounts`}
        component={AccountReports}
      />
    </Switch>
  </>
)
Reports.propTypes = {
  match: PropTypes.object
}

export default Reports
