import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'

import ScrollToTop from './hocs/ScrollToTop'
import PrivateRoute from './hocs/PrivateRoute'

import DashboardRoutes from 'pages/dashboard/DashboardRoutes'
import AuthRoutes from 'pages/user-auth'
import PageNotFound from 'pages/PageNotFound'

import 'styles/styles.css'
import WithProducts from 'hocs/WithProducts'

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <div className="app">
          <Switch>
            <Route path="/auth" component={AuthRoutes} />
            <PrivateRoute path="/" component={DashboardRoutes} />
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </ScrollToTop>
    </Router>
  )
}

App.propTypes = {
  setExchangeRate: PropTypes.func
}

export default WithProducts(App)
