import React, { useEffect, useRef, useState } from 'react'
import { Space, Breadcrumb, Tag, Button } from 'antd'
// import { toast } from 'react-toastify'

import { getHeaders, tasksUrl } from '../../../config'
import axios from 'axios'
import FilterTable from 'components/Forms/searchFilter'
import PageLoader from 'components/Layouts/PageLoader'
import { CSVLink } from 'react-csv'

const Department = props => {
  const [departments, setDepartments] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRowData, setSelectedRowData] = useState([])
  // eslint-disable-next-line
  const [meta, setMeta] = useState({})

  const structureData = data => {
    const filteredDepartments = data.map(dep => {
      let ownersData = ''
      dep.owners.forEach((ow, i) => {
        // console.log(ow, i)
        if (i === 0) {
          ownersData = `${ow.firstName} ${ow.lastName}`
        } else {
          ownersData += `, ${ow.firstName} ${ow.lastName}`
        }
      })
      // console.log(ownersData)

      return {
        ...dep,
        createdAt: new Date(dep.createdAt).toDateString(),
        owners: dep.owners,
        deadline: new Date(dep.deadline).toDateString(),
        dateCompleted: dep.dateCompleted
          ? new Date(dep.dateCompleted).toDateString()
          : 'N/A',
        status: dep.status && `${dep.status.title}`,
        project: dep.project && dep.project.name,
        task: dep.taskType && dep.taskType.name,
        client: dep.project && dep.project.client.name,
        ownersData: ownersData
      }
    })
 
    return filteredDepartments
  }

  let formatUrl = url => url.replace(/http/g, 'https')

  const getDepartments = (data_url = tasksUrl + '/all') => {
    setLoading(true)
    axios
      .get(data_url, { headers: getHeaders() })
      .then(res => {
        setMeta({
          ...res.data
        })
        console.log(res.data)
        if(JSON.parse(localStorage.getItem('traffic.creativehubonline.com/account-reports')).length === res.data.count){
          console.log(
            'TOTAL:',
            JSON.parse(localStorage.getItem('traffic.creativehubonline.com/account-reports')).length , "Adding ", res.data.count
          )
          setDepartments(
            JSON.parse(localStorage.getItem('traffic.creativehubonline.com/account-reports'))
          )
          setLoading(false)
        }else if(res.data.count > JSON.parse(localStorage.getItem('traffic.creativehubonline.com/account-reports')).length){
          if (!res.data.previous){
            localStorage.setItem('traffic.creativehubonline.com/account-reports', JSON.stringify([]))
          }

          let finalData = structureData(res.data.data)
          let existingData = localStorage.getItem('traffic.creativehubonline.com/account-reports')
            ? JSON.parse(localStorage.getItem('traffic.creativehubonline.com/account-reports'))
            : []
          let finalll = existingData.concat(finalData)
          localStorage.setItem(
            'traffic.creativehubonline.com/account-reports',
            JSON.stringify(finalll)
          )

          // Display in the table
          setDepartments(finalll)
          console.log(
            'TOTAL',
            JSON.parse(localStorage.getItem('traffic.creativehubonline.com/account-reports'))
              .length,typeof(JSON.parse(localStorage.getItem('traffic.creativehubonline.comaccount-reports'))),"Used 2nd route", res.data.count
          )

          if (res.data.next) {
            getDepartments(formatUrl(res.data.next))
          } else {
            setDepartments(
              JSON.parse(localStorage.getItem('traffic.creativehubonline.com/account-reports'))
            )
            setLoading(false)
          }
        }
      })
      .catch(err => {
        setLoading(false)
        console.log('error')
      })
  }

  let accReportMounted = useRef()
  useEffect(() => {
    if (!accReportMounted.current) {
      if(localStorage.getItem('traffic.creativehubonline.com/account-reports')!==null && JSON.parse(localStorage.getItem('traffic.creativehubonline.com/account-reports')).length){
        getDepartments()
      } else {
        localStorage.setItem('traffic.creativehubonline.com/account-reports', JSON.stringify([]))
        getDepartments()
      }
      // localStorage.setItem('traffic.creativehubonline.com/account-reports', JSON.stringify([]))
      // getDepartments()
      accReportMounted.current = true
    }

    // eslint-disable-next-line
  }, [])

  const handleDetail = record => {
    props.history.push(`/tasks/${record.id}`)
  }

  const columns = [
    {
      title: 'Assigned To',
      dataIndex: 'owners',
      key: 'owners',
      width: 120,
      render: (text, record) =>
        record.owners.map((ow, i) => (
          <Tag key={i}>{`${ow.firstName} ${ow.lastName}`}</Tag>
        ))
    },
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
      width: 120
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      width: 120
    },
    {
      title: 'Project Name',
      dataIndex: 'project',
      key: 'project',
      width: 120
    },
    {
      title: 'Date Completed',
      dataIndex: 'dateCompleted',
      key: 'dateCompleted',
      width: 120
    },
    {
      title: 'Timeliness',
      dataIndex: 'timeliness',
      key: 'timeliness',
      width: 120
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 120
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150
      // fixed: 'left'
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120
    },

    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, record) => (
        <Space size="middle">
          {/* <span onClick={handleDetail.bind(this, record)}>View</span> */}
          {/* eslint-disable-next-line */}
          <a href="" onClick={handleDetail.bind(this, record)}>
            View
          </a>
        </Space>
      )
    }
  ]

  const dataHeaders = [
    {
      label: 'Task',
      key: 'task'
    },
    {
      label: 'Client',
      key: 'client'
    },
    {
      label: 'Project Name',
      key: 'project'
    },
    {
      label: 'Date Completed',
      key: 'dateCompleted'
    },
    {
      label: 'Timeliness',
      key: 'timeliness'
    },
    {
      label: 'Deadline',
      key: 'deadline'
    },
    {
      label: 'Assigned To',
      key: 'ownersData'
    },
    {
      label: 'Status',
      key: 'status'
    },
    {
      label: 'Date Added',
      key: 'createdAt'
    }
  ]

  const excelData = []
  const onSelectChange = selectedRowKeys => {
    // eslint-disable-next-line
    selectedRowKeys.map(key => {
      // eslint-disable-next-line
      departments.map(data => {
        if (key === data.id) {
          excelData.push(data)
          setSelectedRowData(excelData)
        }
      })
    })
    console.log('selectedRowKeys changed: ', selectedRowKeys)
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const hasSelected = selectedRowKeys.length > 0

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row">
        <div className="col-lg-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Account Reports</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">
              <div className="utils__title">
                <span>All Accounts Performance</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                <Button
                  type="primary"
                  // onClick={download}
                  disabled={!hasSelected}
                  loading={loading}
                >
                  <CSVLink
                    data={selectedRowData}
                    headers={dataHeaders}
                    filename={'Account-Report.csv'}
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                </Button>
                <span style={{ marginLeft: 8 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items`
                    : ''}
                </span>
              </div>
              <FilterTable
                dataSource={departments}
                rowSelection={rowSelection}
                columns={columns}
                searchFields={[
                  'task',
                  'name',
                  'client',
                  // 'owners',
                  'deadline',
                  'status',
                  'worker',
                  'project',
                  'createdAt'
                ]}
                // pagination={false}
                // meta={meta}
                // getData={getDepartments}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLoader>
  )
}

export default Department
