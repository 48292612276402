import React, { useEffect, useState } from 'react'
import { Space, Button, Breadcrumb, Tag } from 'antd'
// import { toast } from 'react-toastify'

import { getHeaders, projectsUrl } from '../../../config'
import axios from 'axios'
import FilterTable from 'components/Forms/searchFilter'
import PageLoader from 'components/Layouts/PageLoader'

const Department = props => {
  const [departments, setDepartments] = useState([])
  const [loading, setLoading] = useState(false)
  // const [selectedRowKeys, setSelectedRowKeys] = useState([])
  // const [selectedRowData, setSelectedRowData] = useState([])

  const getDepartments = () => {
    setLoading(true)
    axios
      .get(projectsUrl + `/${props.match.params.projectId}`, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        console.log(res.data)
        console.log(res.data.tasks)
        const filteredDepartments = res.data.tasks.map(dep => {
          return {
            ...dep,
            createdAt: new Date(dep.createdAt).toDateString(),
            owner: dep.owners,
            deadline: new Date(dep.deadline).toDateString(),
            status: `${dep.status.title}`,
            project: dep.project.name,
            task: dep.taskType ? dep.taskType.name : 'N/A'
          }
        })
        setDepartments(filteredDepartments)
      })
      .catch(err => {
        setLoading(false)
        console.log('error')
      })
  }

  useEffect(() => {
    getDepartments()
    // eslint-disable-next-line
  }, [])

  const handleDetail = record => {
    props.history.push(
      `/projects/${props.match.params.projectId}/tasks/${record.id}`
    )
  }

  const columns = [
    {
      title: 'Task Name',
      dataIndex: 'task',
      key: 'task',
      width: 120
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120
      // fixed: 'left'
    },
    {
      title: 'Task Owners',
      dataIndex: 'owners',
      key: 'owners',
      width: 120,
      render: (text, record) =>
        record.owners.map((ow, i) => (
          <Tag key={i}>{`${ow.firstName} ${ow.lastName}`}</Tag>
        ))
    },

    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 120
    },

    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120
    },

    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 80,

      render: (text, record) => (
        <Space size="middle">
          {/* <span onClick={handleDetail.bind(this, record)}>View</span> */}
          {/* eslint-disable-next-line */}
          <a href="#" onClick={handleDetail.bind(this, record)}>
            View
          </a>
        </Space>
      )
    }
  ]

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row">
        <div className="col-lg-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/projects/${props.match.params.projectId}`}>
              Project Details
            </Breadcrumb.Item>
            <Breadcrumb.Item>Tasks</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">
              <div className="utils__title">
                <span>Project Tasks</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                <Button
                  type="primary"
                  onClick={() =>
                    props.history.push(
                      `/projects/${props.match.params.projectId}/tasks/add-new`
                    )
                  }
                >
                  Add New Task
                </Button>
                {/*
                <span style={{ marginLeft: 8 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items`
                    : ""}
                </span> */}
              </div>
              {/* <Table
                columns={columns}
                // rowSelection={rowSelection}
                dataSource={departments}
                pagination={true}
                boardered
              /> */}

              <FilterTable
                dataSource={departments}
                columns={columns}
                searchFields={
                  []
                  // ['task', 'deadline', 'status']
                }
              />
            </div>
          </div>
        </div>
      </div>
    </PageLoader>
  )
}

export default Department
