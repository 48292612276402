import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import bqUserIcon from 'img/bq-user-icon.svg'
import WithUser from 'hocs/WithUser'
import { Menu, Dropdown } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faBars } from '@fortawesome/free-solid-svg-icons'

// Function to clear IndexedDB store
function clearIndexedDBStore(dbName, storeName) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onerror = (event) => {
      reject(`Error opening database: ${event.target.error}`);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);

      const clearRequest = store.clear();

      clearRequest.onerror = (event) => {
        reject(`Error clearing store: ${event.target.error}`);
      };

      clearRequest.onsuccess = () => {
        resolve('Store cleared successfully');
      };
    };
  });
}

const DashboardNavbar = props => {
  const handleLogout = async () => {
    try {
      // Clear IndexedDB store
      await clearIndexedDBStore('MindfullDB', 'projects_Store');
      
      // Call the unsetUser function from props
      props.unsetUser();
      
      // Redirect to login page or home page
      // props.history.push('/login'); // Adjust this path as needed

      console.log('Logged out and IndexedDB store cleared');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
    </Menu>
  )

  return (
    <div className="dashboard_navbar">
      <div className="dashboard_navbar_left" onClick={props.handleOpen}>
        <FontAwesomeIcon icon={faBars} />
      </div>

      <Dropdown overlay={menu} placement="bottom">
        <div className="dashboard_navbar_right">
          <span>
            <img src={bqUserIcon} alt="user-icon" />{' '}
            {`${props.authUser.user &&
              props.authUser.user.firstName.toUpperCase()} ${props.authUser.user
              .lastName && props.authUser.user.lastName.toUpperCase()}`}
          </span>
          <span
            style={{ fontSize: '1.6rem' }}
            className="dashboard_navbar_left_text ml-1rem"
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
      </Dropdown>
    </div>
  )
}

DashboardNavbar.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  handleOpen: PropTypes.func,
  unsetUser: PropTypes.func,
  authUser: PropTypes.object
}

export default withRouter(WithUser(DashboardNavbar))