import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import BasicTooltip from './BasicTooltip'
import { DatePicker, Form } from 'antd'
import moment from 'moment'

const DatePickerInput = props => {
  const [showToolTip, setShowToolTip] = useState(false)

  const { toolTipLabel, name, label, required, value, handleChange } = props
  useEffect(() => {
    console.log(props.value)
    toolTipLabel && setShowToolTip(true)
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <Form.Item
        name={name}
        label={
          <>
            {label ? label : ''}{' '}
            {showToolTip ? (
              <>
                <BasicTooltip label={toolTipLabel} />
              </>
            ) : (
              ''
            )}
          </>
        }
        initialValue={moment(value, 'YYYY-MM-DD')}
        rules={[
          {
            required: required
          }
        ]}
        colon={false}
      >
        <DatePicker
          value={moment(value, 'YYYY-MM-DD')}
          defaultValue={moment(value, 'YYYY-MM-DD')}
          placeholder="DD/MM/YYYY"
          dateFormat="DD/MM/YYYY"
          onChange={handleChange}
          style={{ width: '100%' }}
          className="form_input_muted"
        />

      </Form.Item>
    </React.Fragment>
  )
}

DatePickerInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  handleChange: PropTypes.func,
  handleDateChangeRaw: PropTypes.func,
  toolTipLabel: PropTypes.string,
  required: PropTypes.bool
}

export default DatePickerInput
