import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Select, Form } from 'antd'
import BasicTooltip from './BasicTooltip'

const { Option } = Select

const SelectInput = props => {
  const [showToolTip, setShowToolTip] = useState(false)
  const {
    toolTipLabel,
    name,
    label,
    toolTipLabel2,
    required,
    value,
    onChange,
    optionList,
    mode,
    placeholder
  } = props

  useEffect(() => {
    toolTipLabel && setShowToolTip(true)
  }, [toolTipLabel])
  return (
    <React.Fragment>
      <Form.Item
        name={name}
        label={
          <>
            {label ? label : ''}{' '}
            {showToolTip ? (
              <>
                <BasicTooltip label={toolTipLabel} label2={toolTipLabel2} />
              </>
            ) : (
              ''
            )}
          </>
        }
        initialValue={value}
        rules={[
          {
            required: required
          }
        ]}
        colon={false}
      >
        <Select
          value={value}
          name={name}
          onChange={onChange}
          mode={mode}
          placeholder={placeholder}
          defaultValue={value}
          optionLabelProp="label"
          optionFilterProp="label"
          style={{ width: '100%', backgroundColor: '#f2f2f2' }}
          className="form_input_muted"
        >
          {optionList.map((o, i) => (
            <Option value={o.value} label={o.label} key={i}>
              {o.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </React.Fragment>
  )
}

SelectInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  inputType: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  toolTipLabel: PropTypes.string,
  toolTipLabel2: PropTypes.string,
  optionList: PropTypes.array,
  mode: PropTypes.string,
  required: PropTypes.bool
}

export default SelectInput
