import { createStore, compose, combineReducers, applyMiddleware } from 'redux'

import thunk from 'redux-thunk'

import {
  products,
  motorProducts,
  healthProducts,
  lifeProducts,
  propertyProducts,
  compareProducts,
  showMessenger,
  payload
} from '../components/Product/reducers'
import { authUser } from 'pages/user-auth/reducers'
import { payments, exchangeRate } from 'components/Payments/reducers'
import { search } from 'components/Search/reducers'
import { region } from 'components/Region/reducers'

const reducers = {
  products,
  motorProducts,
  healthProducts,
  lifeProducts,
  propertyProducts,
  compareProducts,
  payload,
  showMessenger,
  authUser,
  payments,
  exchangeRate,
  search,
  region
}

const reducer = combineReducers(reducers)

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)))

export default store
