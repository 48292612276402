import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Col, Space, Form } from 'antd'
import {
  getHeaders,
  clientsUrl,
  successNotification,
  errorNotification
} from '../../../config'
// import { toast } from 'react-toastify'
import BasicInput from 'components/Forms/BasicInput'
// import DatePickerInput from 'components/Forms/DatePickerInput'
import SelectInput from 'components/Forms/SelectInput'
// import moment from 'moment'
import PropTypes from 'prop-types'

const taxExemptedOptions = [
  {
    label: 'Yes',
    value: true
  },
  { label: 'No', value: false }
]

const Update = props => {
  const [client, setClient] = useState({
    email: props.user.email,
    name: props.user.name,
    phoneNumber: props.user.phoneNumber,
    isTaxExempted: props.user.isTaxExempted
  })

  // const [disableForm, setDisableForm] = useState(false)
  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)
  // const [showModal, setShowModal] = useState(false)

  const getClient = () => {
    setClient(props.user)
  }

  useEffect(() => {
    getClient()
    // eslint-disable-next-line
  }, [])

  const handleInputChange = stateName => event => {
    setClient({ ...client, [stateName]: event.target.value })
  }

  // const handleDateUpdate = stateName => date => {
  //   setClient({ ...client, [stateName]: date })
  // }

  const handleSelect = stateName => value => {
    setClient({
      ...client,
      [stateName]: value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    setLoading(true)
    axios
      .put(clientsUrl + '/' + client.id, client, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        props.handleCancel()
        successNotification('Client Updated')
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error updating client, retry.')
      })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="mb-1rem">
            <Form layout="vertical" className="p-2rem">
              <div className="row">
                <div className="col-md-5">
                  <BasicInput
                    label="Client Name"
                    value={client.name}
                    name={'client name'}
                    placeholder="client name"
                    handleChange={handleInputChange('name')}
                    required={true}
                  />
                  {serverErrors.name &&
                    serverErrors.name.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}
                </div>

                <div className="col-md-5 offset-md-2 offset-sm-0 ">
                  <BasicInput
                    label="Email Address"
                    value={client.email}
                    name={'email address'}
                    placeholder="email address"
                    handleChange={handleInputChange('email')}
                    required={false}
                  />
                  {serverErrors.email &&
                    serverErrors.email.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}
                </div>

                <div className="col-md-5">
                  <BasicInput
                    label="Office Phone Number"
                    value={client.phoneNumber}
                    name={'phone number'}
                    placeholder="phone number"
                    handleChange={handleInputChange('phoneNumber')}
                    required={false}
                  />

                  {serverErrors.phoneNumber &&
                    serverErrors.phoneNumber.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}
                </div>

                <div className="col-md-5 offset-md-2 offset-sm-0">
                  <SelectInput
                    placeholder="select"
                    label="Is Tax Exempted?"
                    name="tax exemption"
                    value={client.isTaxExempted}
                    onChange={handleSelect('isTaxExempted')}
                    optionList={taxExemptedOptions}
                    required={true}
                  />
                  {serverErrors.isTaxExempted &&
                    serverErrors.isTaxExempted.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}
                </div>

                {serverErrors.nonFieldErrors &&
                  serverErrors.nonFieldErrors.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}

                <Col md={24}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Space>
                      <Button onClick={() => props.handleCancel()}>
                        Close
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={loading}
                      >
                        Submit
                      </Button>
                    </Space>
                  </div>
                </Col>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

Update.propTypes = {
  user: PropTypes.object,
  handleCancel: PropTypes.object
}

export default Update
