import React, { useEffect, useState, useRef } from 'react'
import { Button, Space, Breadcrumb } from 'antd'
// import { toast } from 'react-toastify'
import { getHeaders, projectsUrl } from '../../../config'
import axios from 'axios'
import FilterTable from 'components/Forms/searchFilter'
import PageLoader from 'components/Layouts/PageLoader' 

const DB_NAME = 'MindfullDB'
const STORE_NAME = 'projects_Store'
const DB_VERSION = 1

const Department = props => {
  const [departments, setDepartments] = useState([])
  const [loading, setLoading] = useState(false)

  const dbRef = useRef(null)

  const initDB = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION)

      request.onerror = (event) => reject("IndexedDB error: " + event.target.error)

      request.onsuccess = (event) => {
        dbRef.current = event.target.result
        resolve()
      }

      request.onupgradeneeded = (event) => {
        const db = event.target.result
        db.createObjectStore(STORE_NAME, { keyPath: "id" })
      }
    })
  }

  const getLocalData = () => {
    return new Promise((resolve, reject) => {
      if(!dbRef.current){
        resolve([]);
        return;
      }
      const transaction = dbRef.current.transaction([STORE_NAME], "readonly")
      const store = transaction.objectStore(STORE_NAME)
      const request = store.getAll()

      request.onerror = (event) => reject("Error fetching data: " + event.target.error)
      request.onsuccess = (event) => resolve(event.target.result || [])
    })
  }

  const setLocalData = (data) => {
    return new Promise((resolve, reject) => {
      if (!dbRef.current) {
        reject("Database not initialized")
        return
      }
      const transaction = dbRef.current.transaction([STORE_NAME], "readwrite")
      const store = transaction.objectStore(STORE_NAME)

      // Clear existing data
      store.clear().onsuccess = () => {
        // Add new data
        data.forEach(item => {
          store.add(item)
        })
      }

      transaction.oncomplete = () => resolve()
      transaction.onerror = (event) => reject("Error storing data: " + event.target.error)
    })
  }


  const structureData = data => {
    const filteredDepartments = data.map(dep => {
      return {
        ...dep,
        createdAt: new Date(dep.createdAt).toDateString(),
        client: dep.client.name,
        owner: `${dep.owner.firstName} ${dep.owner.lastName}`,
        deadline: new Date(dep.deadline).toDateString(),
        status: `${dep.status.title}`,
        projectType: dep.projectType.title
      }
    })

    return filteredDepartments
  }

  let formatUrl = url => url.replace(/http/g, 'https')

  const mergeData = (localData, newData) => {
    const mergedMap = new Map(localData.map(item => [item.id, item]))
    newData.forEach(newItem => {
      const existingItem = mergedMap.get(newItem.id)
      if (!existingItem || new Date(newItem.updatedAt) > new Date(existingItem.updatedAt)) {
        mergedMap.set(newItem.id, newItem)
      }
    })
    return Array.from(mergedMap.values())
  }

  const getDepartments = async (data_url = projectsUrl) => {
    setLoading(true)
    try {
      const localData = await getLocalData();
      const response = await axios.get(data_url, { headers: getHeaders() })
      const { data, next } = response.data

      const structuredNewData = structureData(data)
      const mergedData = mergeData(localData, structuredNewData)

      if (JSON.stringify(localData) !== JSON.stringify(mergedData) || next) {
            console.log('Changes detected, updating data');
            await setLocalData(mergedData);
            setDepartments(mergedData);

            if (next) {
              await getDepartments(formatUrl(next));
            }
        } else {
          console.log('No changes detected, using local data');
          setDepartments(localData);
        }
      
    } catch (err) {
      console.error('Error fetching departments:', err)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    const setup = async () => {
      try {
        await initDB()
        await getDepartments()
      } catch (error) {
        console.error('Setup error:', error)
      }
    }
    setup()
    // eslint-disable-next-line
  }, [])

  const handleDetail = record => {
    props.history.push(`/projects/${record.id}`)
  }

  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
      width: 120
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      width: 120
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 120,
      sorter: (a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        return dateA - dateB;
      },
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100
      // fixed: 'left'
    },
    {
      title: 'Project Type',
      dataIndex: 'projectType',
      key: 'projectType',
      width: 100
      // fixed: 'left'
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        return dateA - dateB;
      },
      sortDirections: ['descend', 'ascend']
      
    },
    {
      title: 'Project Owner',
      dataIndex: 'owner',
      key: 'owner',
      width: 120
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 80,

      render: (text, record) => (
        <Space size="middle">
          {/* <span onClick={handleDetail.bind(this, record)}>View</span> */}
          {/* eslint-disable-next-line */}
          <a href="#" onClick={handleDetail.bind(this, record)}>
            View
          </a>
        </Space>
      )
    }
  ]

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row">
        <div className="col-lg-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Projects</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">
              <div className="utils__title">
                <span>Projects</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                <Button
                  type="primary"
                  onClick={() => props.history.push('/projects/add-new')}
                >
                  Add New Project
                </Button>
                {/*
                <span style={{ marginLeft: 8 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items`
                    : ""}
                </span> */}
              </div>
              {/* <Table
                columns={columns}
                // rowSelection={rowSelection}
                dataSource={departments}
                pagination={true}
                boardered
              /> */}

              <FilterTable
                dataSource={departments}
                columns={columns}
                searchFields={
                  // []
                  ['name', 'client','status', 'projectType', 'owner']
                }
              />
            </div>
          </div>
        </div>
      </div>
    </PageLoader>
  )
}

export default Department
