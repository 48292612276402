import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
// eslint-disable-next-line
import { Button, Space, Breadcrumb, Modal, Tabs } from 'antd'
// eslint-disable-next-line
import {
  getHeaders,
  projectsUrl,
  paymentsUrl,
  whtUrl,
  successNotification,
  errorNotification
} from '../../../config'
import Update from './Update'
// import moment from 'moment'
import DeadlineForm from './DeadlineForm'
import PaymentForm from './PaymentForm'
import WHTForm from './WHTForm'
import FilterTable from 'components/Forms/searchFilter'
import PageLoader from 'components/Layouts/PageLoader'

const { TabPane } = Tabs

// const DB_NAME = 'MindfullDB'
const STORE_NAME = 'projects_Store'
// const DB_VERSION = 1

const Detail = props => {
  const [client, setClient] = useState({
    name: '',
    description: '',
    deadline: '',
    totalCost: '',
    startAt: '',
    status: '',
    owner: '',
    ownerId: '',
    client: '',
    clientId: '',
    statusId: '',
    projectType: '',
    projectTypeId: '',
    deadlineChanges: '',
    paymentDeadline: '',
    amountDueLess: '',
    totalAmount: '',
    totalCashPayment: '',
    serviceType: '',
    // certificatesProvided: '',
    recurringBill: '',
    retainerAccount: ''
  })
  const [deadlines, setDeadlines] = useState([])
  const [payments, setPayments] = useState([])
  const [wht, setWht] = useState([])
  const [payload, setPayload] = useState({})

  // const [disableForm, setDisableForm] = useState(false)
  // eslint-disable-next-line
  const [serverErrors, setServerErrors] = useState({})
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showDeadlineModal, setShowDeadlineModal] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [showWHTModal, setShowWHTModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const dbRef = useRef(null)
  const getClient = () => {
    setLoading(true)
    axios
      .get(projectsUrl + '/' + props.match.params.id, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        setClient({
          ...res.data,
          deadline: new Date(res.data.deadline).toDateString(),
          status: `${res.data.status ? res.data.status.title : 'N/A'}`,
          statusId: res.data.status.id,
          projectTypeId: res.data.projectType.id,
          owner: `${res.data.owner.firstName} ${res.data.owner.lastName}`,
          client: res.data.client.name,
          createdAt: new Date(res.data.createdAt).toDateString(),
          ownerId: res.data.owner.id,
          clientId: res.data.client.id,
          projectType: res.data.projectType
            ? res.data.projectType.title
            : 'N/A',
          serviceType: res.data.serviceType ? res.data.serviceType.title : 'N/A'
        })
        console.log(client)

        setPayload(res.data)
        console.log(payload)
        setDeadlines(
          res.data.deadlineChanges
            ? res.data.deadlineChanges.map(dead => {
                return {
                  ...dead,
                  newDate: new Date(dead.newDate).toDateString(),
                  createdAt: new Date(dead.createdAt).toDateString()
                }
              })
            : []
        )

        setPayments(
          res.data.payments
            ? res.data.payments.map(pay => {
                return {
                  ...pay,
                  amount: nf.format(pay.amount),
                  dateReceived: new Date(pay.dateReceived).toDateString(),
                  createdAt: new Date(pay.createdAt).toDateString(),
                  updatedAt: new Date(pay.updatedAt).toDateString()
                }
              })
            : []
        )

        setWht(
          res.data.whtCertificates
            ? res.data.whtCertificates.map(wht => {
                return {
                  ...wht,
                  dateReceived: new Date(wht.dateReceived).toDateString(),
                  createdAt: new Date(wht.createdAt).toDateString(),
                  updatedAt: new Date(wht.updatedAt).toDateString()
                }
              })
            : []
        )
      })
      .catch(err => {
        setLoading(false)
        console.log('error')
      })
  }

  const changeDeadline = deadline => {
    axios
      .put(
        projectsUrl + '/' + props.match.params.id,
        {
          ...client,
          deadline,
          owner: client.ownerId,
          status: client.statusId,
          projectType: client.projectTypeId
        },
        { headers: getHeaders() }
      )
      .then(res => {
        successNotification('Deadline Changed')
        getClient()
      })
  }

  function clearIndexedDBData() {
    return new Promise((resolve, reject) => {
      if (!dbRef.current) {
        reject("Database not initialized");
        return;
      }
  
      const transaction = dbRef.current.transaction([STORE_NAME], "readwrite");
      const store = transaction.objectStore(STORE_NAME);
  
      const request = store.clear();
  
      request.onerror = (event) => {
        reject("Error clearing data: " + event.target.error);
      };
  
      request.onsuccess = () => {
        console.log("IndexedDB data cleared successfully");
        resolve();
      };
    });
  }

  const handleDeleteProject = () => {
    axios
      .delete(projectsUrl + '/' + props.match.params.id, {
        headers: getHeaders()
      })
      .then(res => {
        successNotification('Project Deleted')
        clearIndexedDBData()
        props.history.push('/projects')
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error deleting projects, retry.')
      })
  }

  useEffect(() => {
    getClient()
    // eslint-disable-next-line
  }, [])

  let nf = new Intl.NumberFormat('en-US', {
    // style: "currency",
    // currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  const deadlineColumns = [
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      width: 120
    },
    {
      title: 'New Deadline Date',
      dataIndex: 'newDate',
      key: 'newDate',
      width: 120
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120
    }
  ]

  const handleDeletePayment = record => {
    axios
      .delete(paymentsUrl + `/${record.id}`, { headers: getHeaders() })
      .then(res => {
        successNotification('Payment Deleted!')
        getClient()
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error deleting payment, retry.')
      })
  }

  const handleDeleteWht = record => {
    axios
      .delete(whtUrl + `/${record.id}`, { headers: getHeaders() })
      .then(res => {
        successNotification('WHT Certificate Deleted!')
        getClient()
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error deleting wht certificate, retry.')
      })
  }

  const paymentColumns = [
    {
      title: 'Amount Paid (GHS)',
      dataIndex: 'amount',
      key: 'amount',
      width: 120
    },
    {
      title: 'Date Received',
      dataIndex: 'dateReceived',
      key: 'dateReceived',
      width: 120
    },
    {
      title: 'Timestamp',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 80,

      render: (text, record) => (
        <Space size="middle">
          <span
            onClick={handleDeletePayment.bind(this, record)}
            style={{ color: 'red' }}
          >
            DELETE
          </span>
          {/* eslint-disable-next-line */}
          {/* <a href="#" onClick={handleDetail.bind(this, record)}>
            Delete
          </a> */}
        </Space>
      )
    }
  ]

  const whtColumns = [
    {
      title: 'Amount (GHS)',
      dataIndex: 'amount',
      key: 'amount',
      width: 120
    },
    {
      title: 'Date Received',
      dataIndex: 'dateReceived',
      key: 'dateReceived',
      width: 120
    },
    {
      title: 'Timestamp',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 80,

      render: (text, record) => (
        <Space size="middle">
          <span
            onClick={handleDeleteWht.bind(this, record)}
            style={{ color: 'red' }}
          >
            DELETE
          </span>
          {/* eslint-disable-next-line */}
          {/* <a href="#" onClick={handleDetail.bind(this, record)}>
            Delete
          </a> */}
        </Space>
      )
    }
  ]

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/projects">Projects</Breadcrumb.Item>
            <Breadcrumb.Item>Project Details</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="card mb-4rem">
                <div className="card-header">Project Details</div>
                <div className="card-body">
                  <div
                    className="row"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Project Name</b>
                      </div>{' '}
                      {client.name}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      {/* <div>
                        <b>Project Description</b>
                      </div>
                      {client.description} */}
                      <div>
                        <b>Number of Times Deadline has been changed</b>
                      </div>
                      {`${deadlines.length} time${
                        deadlines.length > 1 ? 's' : ''
                      }`}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Start Date</b>
                      </div>
                      {new Date(client.startAt).toDateString()}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Deadline Date</b>
                      </div>
                      {client.deadline}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Is Project a Retainer Account</b>
                      </div>
                      {client.retainerAccount ? 'YES' : 'NO'}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Project Value (No Taxes Applied)</b>
                      </div>
                      {`GHS ${nf.format(client.totalCost)}`}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Project Status</b>
                      </div>
                      {client.status}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Client Name</b>
                      </div>
                      {client.client}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Project Owner</b>
                      </div>
                      {client.owner}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Project Type</b>
                      </div>
                      {client.projectType}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Project Category</b>
                      </div>
                      {client.serviceType}
                    </div>

                    {!client.retainerAccount ? (
                      <>
                        <div className="col-md-5 mb-2rem">
                          <div>
                            <b>WHT Status</b>
                          </div>
                          {client.whtStatus}
                        </div>

                        <div className="col-md-5 mb-2rem">
                          <div>
                            <b>Recurring Bill</b>
                          </div>
                          {client.recurringBill ? 'Yes' : 'No'}
                        </div>

                        <div className="col-md-5 mb-2rem">
                          <div>
                            <b>Amount Due Less WHT</b>
                          </div>
                          GHS {nf.format(client.amountDueLess)}
                        </div>

                        <div className="col-md-5 mb-2rem">
                          <div>
                            <b>Deadline for Full Collection</b>
                          </div>
                          {new Date(client.finalPaymentDeadline).toDateString()}
                        </div>

                        <div className="col-md-5 mb-2rem">
                          <div>
                            <b>Total Project Value (Taxes Applied)</b>
                          </div>
                          GHS {nf.format(client.totalAmount)}
                        </div>

                        <div className="col-md-5 mb-2rem">
                          <div>
                            <b>Total Payments Made</b>
                          </div>
                          GHS {nf.format(client.totalCashPayment)}
                          {/* (Arrears: GHS{' '}
                      {client.totalAmount - client.totalCashPayment}) */}
                        </div>

                        <div className="col-md-5 mb-2rem">
                          {/* <div>
                        <b>Number of Times Deadline has been changed</b>
                      </div>
                      {`${deadlines.length} time${
                        deadlines.length > 1 ? 's' : ''
                      }`} */}
                          <div>
                            <b>Payment Status</b>
                          </div>
                          {client.paymentStatus}
                        </div>
                      </>
                    ) : null}

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Date Completed</b>
                      </div>
                      {client.dateCompleted
                        ? new Date(client.dateCompleted).toDateString()
                        : 'N/A'}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      {/* <div>
                        <b>Payment Status</b>
                      </div>
                      {client.paymentStatus} */}
                    </div>

                    <div className="col-md-7 offset-md-5">
                      <div>
                        <Space>
                          <Button
                            type="primary"
                            onClick={() => {
                              setShowModal(true)
                            }}
                            loading={loading}
                          >
                            Update Project
                          </Button>
                          <Button
                            onClick={() =>
                              props.history.push(`/projects/${client.id}/tasks`)
                            }
                          >
                            View Tasks
                          </Button>
                          <Button
                            type="danger"
                            onClick={() => {
                              setShowDeleteModal(true)
                            }}
                            loading={loading}
                          >
                            Delete Project
                          </Button>
                        </Space>
                      </div>
                    </div>

                    <Modal
                      title="Update Project Details"
                      centered
                      visible={showModal}
                      onOk={() => setShowModal(false)}
                      onCancel={() => setShowModal(false)}
                      footer={null}
                      width={1000}
                    >
                      <Update
                        payload={payload}
                        handleCancel={() => {
                          setShowModal(false)
                          getClient()
                        }}
                      />
                    </Modal>
                  </div>
                </div>
              </div>

              <Tabs defaultActiveKey="1" onChange={() => {}}>
                <TabPane tab="Deadline Changes" key="1">
                  <div className="card mb-5rem">
                    <div className="card-body">
                      <div style={{ marginBottom: 16 }}>
                        <Button
                          type="primary"
                          onClick={() => setShowDeadlineModal(true)}
                        >
                          Change Deadline
                        </Button>
                      </div>

                      <FilterTable
                        dataSource={deadlines}
                        columns={deadlineColumns}
                        searchFields={['reason', 'newDate']}
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Payment(s) Received" key="2">
                  <div className="card mb-5rem">
                    <div className="card-body">
                      <div style={{ marginBottom: 16 }}>
                        <Button
                          type="primary"
                          onClick={() => setShowPaymentModal(true)}
                        >
                          Add Payment
                        </Button>
                      </div>

                      <FilterTable
                        dataSource={payments}
                        columns={paymentColumns}
                        searchFields={['amount', 'note']}
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="WHT Certificate(s) Received" key="3">
                  <div className="card mb-5rem">
                    <div className="card-body">
                      <div style={{ marginBottom: 16 }}>
                        <Button
                          type="primary"
                          onClick={() => setShowWHTModal(true)}
                        >
                          Add WHT Certificate
                        </Button>
                      </div>

                      <FilterTable
                        dataSource={wht}
                        columns={whtColumns}
                        searchFields={['amount', 'note']}
                      />
                    </div>
                  </div>
                </TabPane>
              </Tabs>

              <Modal
                title="Change Project Deadline"
                centered
                visible={showDeadlineModal}
                onOk={() => setShowDeadlineModal(false)}
                onCancel={() => setShowDeadlineModal(false)}
                footer={null}
                width={1000}
              >
                <DeadlineForm
                  moduleId={client.id}
                  moduleChanged={'project'}
                  handleCancel={() => {
                    setShowDeadlineModal(false)
                    getClient()
                  }}
                  handleSuccess={deadline => changeDeadline(deadline)}
                />
              </Modal>

              <Modal
                title="Add Project Payment"
                centered
                visible={showPaymentModal}
                onOk={() => setShowPaymentModal(false)}
                onCancel={() => setShowPaymentModal(false)}
                footer={null}
                width={1000}
              >
                <PaymentForm
                  project={client.id}
                  handleCancel={() => {
                    setShowPaymentModal(false)
                    getClient()
                  }}
                />
              </Modal>

              <Modal
                title="Add WHT Certificate"
                centered
                visible={showWHTModal}
                onOk={() => setShowWHTModal(false)}
                onCancel={() => setShowWHTModal(false)}
                footer={null}
                width={1000}
              >
                <WHTForm
                  project={client.id}
                  handleCancel={() => {
                    setShowWHTModal(false)
                    getClient()
                  }}
                />
              </Modal>

              <Modal
                title="Do You Really Want to Delete This Project?"
                centered
                visible={showDeleteModal}
                onOk={() => setShowDeleteModal(false)}
                onCancel={() => setShowDeleteModal(false)}
                footer={null}
              >
                <div
                  className=""
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button
                    type="danger"
                    onClick={() => {
                      handleDeleteProject()
                    }}
                    loading={loading}
                  >
                    Delete Project
                  </Button>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </PageLoader>
  )
}

export default Detail
