import React, { useState } from 'react'
import axios from 'axios'
import { Button, Col, Space, Breadcrumb, Form } from 'antd'
import {
  getHeaders,
  clientsUrl,
  successNotification,
  errorNotification
} from '../../../config'
// import { toast } from 'react-toastify'
import BasicInput from 'components/Forms/BasicInput'
// import DatePickerInput from 'components/Forms/DatePickerInput'
import SelectInput from 'components/Forms/SelectInput'
// import moment from 'moment'

const taxExemptedOptions = [
  {
    label: 'Yes',
    value: true
  },
  { label: 'No', value: false }
]

const ClientForm = props => {
  const [department, setDepartment] = useState({
    email: '',
    name: '',
    phoneNumber: '',
    isTaxExempted: ''
    // address: '',
    // dateOfBirth: '' || new Date(),
    // occupation: '',
    // passportNumber: '',
    // driversLicenseId: '',
    // confirmed: false
  })

  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const handleInputChange = stateName => event => {
    setDepartment({ ...department, [stateName]: event.target.value })
  }

  // const handleDateUpdate = stateName => date => {
  //   setDepartment({ ...department, [stateName]: date })
  // }

  const handleSelect = stateName => value => {
    setDepartment({
      ...department,
      [stateName]: value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    // let data = {
    //   ...department,
    // }

    setLoading(true)
    axios
      .post(clientsUrl, department, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        props.history.push('/clients')
        successNotification('New client Added')
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error adding client, retry.')
      })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/clients">Clients</Breadcrumb.Item>
            <Breadcrumb.Item>New Client</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">Add New Client</div>
            <div className="card-body">
              <Form className="p-2rem" layout="vertical">
                <div className="row">
                  <div className="col-md-5">
                    <BasicInput
                      label="Client Name"
                      value={department.name}
                      name={'client name'}
                      placeholder="client name"
                      handleChange={handleInputChange('name')}
                      required={true}
                    />
                    {serverErrors.name &&
                      serverErrors.name.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <BasicInput
                      label="Office Phone"
                      value={department.phoneNumber}
                      name={'phone number'}
                      placeholder="phone number"
                      handleChange={handleInputChange('phoneNumber')}
                      required={false}
                    />

                    {serverErrors.phoneNumber &&
                      serverErrors.phoneNumber.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5">
                    <BasicInput
                      label="Email"
                      value={department.email}
                      name={'email'}
                      placeholder="email"
                      handleChange={handleInputChange('email')}
                      required={false}
                    />
                    {serverErrors.email &&
                      serverErrors.email.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5 offset-md-2 offset-sm-0">
                    <SelectInput
                      placeholder="select"
                      label="Is Tax Exempted?"
                      name="tax exemption"
                      value={department.isTaxExempted}
                      onChange={handleSelect('isTaxExempted')}
                      optionList={taxExemptedOptions}
                      required={true}
                    />
                    {serverErrors.isTaxExempted &&
                      serverErrors.isTaxExempted.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  {serverErrors.nonFieldErrors &&
                    serverErrors.nonFieldErrors.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}

                  <Col md={24}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginBottom: '20px'
                      }}
                    >
                      <Space>
                        <Button onClick={() => props.history.push('/clients')}>
                          Go Back
                        </Button>
                        <Button
                          type="primary"
                          onClick={handleSubmit}
                          loading={loading}
                        >
                          Submit
                        </Button>
                      </Space>
                    </div>
                  </Col>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientForm
