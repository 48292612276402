import React, { useState, useEffect } from 'react'
import axios from 'axios'
// eslint-disable-next-line
import { Button, Space, Breadcrumb, Tag, Modal } from 'antd'
// eslint-disable-next-line
import {
  getHeaders, 
  projectsUrl,
  successNotification,
  tasksUrl
} from '../../../config'
// import { toast } from 'react-toastify'
// import BasicInput from 'components/Forms/BasicInput'
// import DatePickerInput from 'components/Forms/DatePickerInput'
// import SelectInput from 'components/Forms/SelectInput'
// import { CheckOutlined, CheckSquareOutlined } from '@ant-design/icons'
import Update from './Update'
import PageLoader from 'components/Layouts/PageLoader'
// import moment from 'moment'
// import DeadlineForm from './DeadlineForm'
// import FilterTable from 'components/Forms/searchFilter'

const Detail = props => {
  const [client, setClient] = useState({
    deadline: '',
    status: '',
    statusId: '',
    owners: [],
    ownerId: '',
    deadlineChanges: ''
  })
  // eslint-disable-next-line
  const [deadlines, setDeadlines] = useState([])

  const [payload, setPayload] = useState({})

  // const [disableForm, setDisableForm] = useState(false)
  // eslint-disable-next-line
  const [serverErrors, setServerErrors] = useState({})
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  // eslint-disable-next-line
  const [showDeadlineModal, setShowDeadlineModal] = useState(false)

  const getClient = () => {
    setLoading(true)
    axios
      .get(tasksUrl + '/' + props.match.params.id + '/get', {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        let data = res.data.data
        setClient({
          ...data,
          deadline: new Date(data.deadline).toDateString(),
          status: `${data.status ? data.status.title : 'N/A'}`,
          statusId: data.status ? data.status.id : '',
          taskType: data.taskType.name,
          owners: data.owners,
          createdAt: new Date(data.createdAt).toDateString()
        })

        setPayload(data)

        setDeadlines(
          data.deadlineChanges
            ? data.deadlineChanges.map(dead => {
                return {
                  ...dead,
                  newDate: new Date(dead.newDate).toDateString(),
                  createdAt: new Date(dead.createdAt).toDateString()
                }
              })
            : []
        )
      })
      .catch(err => {
        setLoading(false)
        console.log('error')
      })
  }

  // eslint-disable-next-line
  const changeDeadline = deadline => {
    axios
      .put(
        projectsUrl + '/' + props.match.params.id,
        {
          ...client,
          deadline,
          owner: client.ownerId,
          status: client.statusId,
          projectType: client.projectTypeId
        },
        { headers: getHeaders() }
      )
      .then(res => {
        successNotification('Deadline Changed')
        getClient()
      })
  }

  useEffect(() => {
    getClient()
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line
  const columns = [
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      width: 120
    },
    {
      title: 'New Deadline Date',
      dataIndex: 'newDate',
      key: 'newDate',
      width: 120
    },
    {
      title: 'Timestamp',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120
    }
    // {
    //   title: 'Action',
    //   key: 'action',
    //   fixed: 'right',
    //   width: 100,
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <span
    //         // onClick={handleDetail.bind(this, record)}
    //         style={{ color: 'blue', cursor: 'pointer' }}
    //       >
    //         View
    //       </span>
    //     </Space>
    //   )
    // }
  ]

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/tasks`}>My Tasks</Breadcrumb.Item>
            <Breadcrumb.Item>My Task Details</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="card mb-4rem">
                <div className="card-header">Project Details</div>
                <div className="card-body">
                  <div
                    className="row"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Task Type</b>
                      </div>{' '}
                      {client.taskType}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Task Owners</b>
                      </div>
                      {client.owners.map((ow, i) => (
                        <Tag key={i}>{`${ow.firstName} ${ow.lastName}`}</Tag>
                      ))}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Deadline Date</b>
                      </div>
                      {client.deadline}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Date Completed</b>
                      </div>
                      {client.dateCompleted
                        ? new Date(client.dateCompleted).toDateString()
                        : 'N/A'}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Timeliness</b>
                      </div>
                      {client.timeliness}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Task Status</b>
                      </div>
                      {client.status}
                    </div>

                    {/* <div className="col-md-5">
                      <div>
                        <b>Number of Times Deadline has been changed</b>
                      </div>
                      {`${deadlines.length} time${
                        deadlines.length > 1 ? 's' : ''
                      }`}
                    </div>
                    <div className="col-md-5 mb-2rem" /> */}

                    <div className="col-md-5 offset-md-7">
                      <div>
                        <Space>
                          <Button onClick={() => props.history.push(`/tasks`)}>
                            Go Back
                          </Button>
                          <Button
                            type="primary"
                            onClick={() => {
                              setShowModal(true)
                            }}
                            loading={loading}
                          >
                            Update Task
                          </Button>
                        </Space>
                      </div>
                    </div>

                    <Modal
                      title="Update Task Details"
                      centered
                      visible={showModal}
                      onOk={() => setShowModal(false)}
                      onCancel={() => setShowModal(false)}
                      footer={null}
                      width={800}
                    >
                      <Update
                        payload={payload}
                        handleCancel={() => {
                          setShowModal(false)
                          getClient()
                        }}
                        match={props.match}
                      />
                    </Modal>
                  </div>
                </div>
              </div>

              {/* <div className="card mb-5rem" style={{ marginTop: '5rem' }}>
                <div className="card-header">
                  <div className="utils__title">
                    <span>Deadline Changes</span>
                  </div>
                </div>
                <div className="card-body">
                  <div style={{ marginBottom: 16 }}>
                    <Button
                      type="primary"
                      onClick={() => setShowDeadlineModal(true)}
                    >
                      Changle Deadline
                    </Button>
             
                  </div>
                  

                  <FilterTable
                    dataSource={deadlines}
                    columns={columns}
                    searchFields={['reason', 'newDate']}
                  />
                </div>
              </div> */}

              {/* <Modal
                title="Change Project Deadline"
                centered
                visible={showDeadlineModal}
                onOk={() => setShowDeadlineModal(false)}
                onCancel={() => setShowDeadlineModal(false)}
                footer={null}
              >
                <DeadlineForm
                  moduleId={client.id}
                  moduleChanged={'project'}
                  handleCancel={() => {
                    setShowDeadlineModal(false)
                    getClient()
                  }}
                  handleSuccess={deadline => changeDeadline(deadline)}
                />
              </Modal> */}
            </div>
          </div>
        </div>
      </div>
    </PageLoader>
  )
}

export default Detail
