import React, { useState, useEffect } from 'react'
import axios from 'axios'
// eslint-disable-next-line
import { Button, Col, Space, Breadcrumb, Form, Switch, Modal } from 'antd'
// eslint-disable-next-line
import {
  getHeaders,
  successNotification,
  errorNotification,
  usersUrl
} from '../../../config'
// import { toast } from 'react-toastify'
// import BasicInput from 'components/Forms/BasicInput'
// import DatePickerInput from 'components/Forms/DatePickerInput'
// import SelectInput from 'components/Forms/SelectInput'
// eslint-disable-next-line
import { CheckSquareOutlined, WarningOutlined } from '@ant-design/icons'
import UserAccountUpdate from './UserAccountUpdate'
// import moment from 'moment'

const Detail = props => {
  const [client, setClient] = useState({
    email: '',
    firstName: '',
    lastName: '',
    otherNames: '',
    phoneNumber: '',
    jobTitle: '',
    userType: '',
    isActive: null
  })

  // const [disableForm, setDisableForm] = useState(false)
  // eslint-disable-next-line
  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const getClient = () => {
    axios
      .get(usersUrl, {
        headers: getHeaders()
      })
      .then(res => {
        setClient(res.data.data[0])
      })
      .catch(err => {
        console.log('error')
      })
  }

  useEffect(() => {
    getClient()
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line
  const handleCheck = stateName => value => {
    setClient({ ...client, [stateName]: value })
  }

  // eslint-disable-next-line
  const handleActivate = stateName => value => {
    setLoading(true)
    axios
      .post(usersUrl + '/' + client.id + '/activate', client, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        setClient({ ...client, [stateName]: value })
        successNotification('User Activated')
      })
      .catch(err => {
        setLoading(false)
        errorNotification('Error activating user, retry.')
      })
  }

  // eslint-disable-next-line
  const handleDeactivate = stateName => value => {
    setLoading(true)
    axios
      .post(usersUrl + '/' + client.id + '/deactivate', client, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        setClient({ ...client, [stateName]: value })
        successNotification('User Deactivated')
      })
      .catch(err => {
        setLoading(false)
        errorNotification('Error deactivating user, retry.')
      })
  }

  const handleDeleteUser = () => {
    axios
      .post(
        usersUrl + '/' + props.match.params.id + '/delete-user',
        {},
        {
          headers: getHeaders()
        }
      )
      .then(res => {
        successNotification('User Deleted')
        props.history.push('/users')
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error deleting user, retry.')
      })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>My Account</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="card mb-4rem">
                <div className="card-header">My Account Detail</div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>First Name</b>
                      </div>{' '}
                      {client.firstName}
                    </div>

                    <div className="col-md-5 offset-md-2 mb-2rem">
                      <div>
                        <b>Last Name</b>
                      </div>
                      {client.lastName}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Phone Number</b>
                      </div>
                      {client.phoneNumber}
                    </div>

                    <div className="col-md-5 offset-md-2 mb-2rem">
                      <div>
                        <b>Email</b>
                      </div>
                      {client.email}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Job Title</b>
                      </div>
                      {client.jobTitle}
                    </div>

                    <div className="col-md-5 offset-md-2 mb-2rem">
                      <div>
                        <b>User Type</b>
                      </div>
                      {client.userType}
                    </div>

                    {/* <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Account Status</b>
                      </div>
                      {client.isActive ? (
                        <div className="" style={{ color: 'green' }}>
                          Active{' '}
                          {<CheckSquareOutlined style={{ color: 'green' }} />}{' '}
                        </div>
                      ) : (
                        <div className="" style={{ color: 'red' }}>
                          Inactive{' '}
                          {<WarningOutlined style={{ color: 'red' }} />}{' '}
                        </div>
                      )}
                    </div>

                    <div className="col-md-5 offset-md-2 mb-2rem">
                      <div>
                        <b>Toggle Account</b>
                      </div>
                      <Switch
                        checked={client.isActive}
                        onChange={
                          client.isActive
                            ? handleDeactivate('isActive')
                            : handleActivate('isActive')
                        }
                      />
                    </div> */}

                    <div className="col-md-5 offset-md-7">
                      <div>
                        <Space>
                          <Button onClick={() => props.history.push('/')}>
                            Go Back
                          </Button>
                          <Button
                            type="primary"
                            onClick={() => {
                              setShowModal(true)
                            }}
                            loading={loading}
                          >
                            Change Password
                          </Button>
                        </Space>
                      </div>
                    </div>

                    <Modal
                      title="Change Password"
                      centered
                      visible={showModal}
                      onOk={() => setShowModal(false)}
                      onCancel={() => setShowModal(false)}
                      footer={null}
                    >
                      <UserAccountUpdate
                        user={client}
                        handleCancel={() => {
                          setShowModal(false)
                          getClient()
                        }}
                      />
                    </Modal>

                    <Modal
                      title="Do You Really Want to Delete This User?"
                      centered
                      visible={showDeleteModal}
                      onOk={() => setShowDeleteModal(false)}
                      onCancel={() => setShowDeleteModal(false)}
                      footer={null}
                    >
                      <div
                        className=""
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Button
                          type="danger"
                          onClick={() => {
                            handleDeleteUser()
                          }}
                          loading={loading}
                        >
                          Delete User
                        </Button>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Detail
