import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Menu, Button } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
  ContainerOutlined,
  ToolOutlined,
  DatabaseOutlined
} from '@ant-design/icons'
import bqLogo from 'img/mindfull-logo.jpg'

const { SubMenu } = Menu

const DashboardSidebar = props => {
  const [collapsed, setCollapsed] = useState()

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  return (
    <React.Fragment>
      <div className="dashboard_sidebar">
        <img
          src={bqLogo}
          alt="logo"
          className="dashboard_sidebar_logo"
          onClick={() => props.history.push('/')}
        />
        <Menu
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['']}
          mode="inline"
          theme="light"
          inlineCollapsed={collapsed}
          className="dashboard_sidebar_menu"
        >
          <Menu.Item
            key="1"
            icon={<PieChartOutlined />}
            onClick={() => props.history.push('/')}
          >
            Overview
          </Menu.Item>

          <SubMenu key="sub1" icon={<ContainerOutlined />} title="Projects">
            <Menu.Item key="2" onClick={() => props.history.push('/projects')}>
              View Projects
            </Menu.Item>
            <Menu.Item
              key="3"
              onClick={() => props.history.push('/projects/add-new')}
            >
              Add Project
            </Menu.Item>
          </SubMenu>

          {/* <Menu.Item
            key="20"
            icon={<ArrowUpOutlined />}
            onClick={() => props.history.push('/claims')}
          >
            Claims
          </Menu.Item>
          <Menu.Item
            key="21"
            icon={<ArrowDownOutlined />}
            onClick={() => props.history.push('/cancellations')}
          >
            Cancellations
          </Menu.Item> */}

          <SubMenu key="sub3" icon={<MenuFoldOutlined />} title="Clients">
            <Menu.Item key="14" onClick={() => props.history.push('/clients')}>
              View Clients
            </Menu.Item>
            <Menu.Item
              key="15"
              onClick={() => props.history.push('/clients/add-new')}
            >
              Add Client
            </Menu.Item>
          </SubMenu>

          <SubMenu key="sub5" icon={<ToolOutlined />} title="My Tasks">
            <Menu.Item key="112" onClick={() => props.history.push('/tasks')}>
              View Tasks
            </Menu.Item>
            {/* <Menu.Item
              key="15"
              onClick={() => props.history.push('/clients/add-new')}
            >
              Add Client
            </Menu.Item> */}
          </SubMenu>

          <SubMenu key="sub6" icon={<DatabaseOutlined />} title="Reports">
            <Menu.Item
              key="113"
              onClick={() => props.history.push('/reports/projects')}
            >
              Project Reports
            </Menu.Item>
            <Menu.Item
              key="115"
              onClick={() => props.history.push('/reports/accounts')}
            >
              Account Reports
            </Menu.Item>
          </SubMenu>

          <SubMenu
            key="sub4"
            icon={<UsergroupAddOutlined />}
            title="Manage Users"
          >
            <Menu.Item key="17" onClick={() => props.history.push('/users')}>
              View Users
            </Menu.Item>
            <Menu.Item
              key="18"
              onClick={() => props.history.push('/users/add-new')}
            >
              Add User
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            key="22"
            icon={<SettingOutlined />}
            onClick={() => props.history.push('/users/my-account')}
          >
            Account Settings
          </Menu.Item>
        </Menu>

        <Button
          type="primary"
          onClick={toggleCollapsed}
          className="dashboard_sidebar_btn"
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
          )}
        </Button>
      </div>
    </React.Fragment>
  )
}

DashboardSidebar.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

export default withRouter(DashboardSidebar)
