import React, { useEffect, useState, useRef } from 'react'
import { Space, Breadcrumb, Button } from 'antd'
// import { toast } from 'react-toastify'

import { getHeaders, projectsUrl } from '../../../config'
import axios from 'axios'
import FilterTable from 'components/Forms/searchFilter'
import PageLoader from 'components/Layouts/PageLoader'
import { CSVLink } from 'react-csv'

const DB_NAME = 'MindfullDB'
const STORE_NAME = 'projects_Store'
const DB_VERSION = 1

const Department = props => {
  const [departments, setDepartments] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRowData, setSelectedRowData] = useState([])

  const dbRef = useRef(null)

  const initDB = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION)

      request.onerror = (event) => reject("IndexedDB error: " + event.target.error)

      request.onsuccess = (event) => {
        dbRef.current = event.target.result
        resolve()
      }

      request.onupgradeneeded = (event) => {
        const db = event.target.result
        db.createObjectStore(STORE_NAME, { keyPath: "id" })
      }
    })
  }

  let nf = new Intl.NumberFormat('en-US', {
    // style: "currency",
    // currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  const getLocalData = () => {
    return new Promise((resolve, reject) => {
      if(!dbRef.current){
        resolve([]);
        return;
      }
      const transaction = dbRef.current.transaction([STORE_NAME], "readonly")
      const store = transaction.objectStore(STORE_NAME)
      const request = store.getAll()

      request.onerror = (event) => reject("Error fetching data: " + event.target.error)
      request.onsuccess = (event) => resolve(event.target.result || [])
    })
  }

  const setLocalData = (data) => {
    return new Promise((resolve, reject) => {
      if (!dbRef.current) {
        reject("Database not initialized")
        return
      }
      const transaction = dbRef.current.transaction([STORE_NAME], "readwrite")
      const store = transaction.objectStore(STORE_NAME)

      // Clear existing data
      store.clear().onsuccess = () => {
        // Add new data
        data.forEach(item => {
          store.add(item)
        })
      }

      transaction.oncomplete = () => resolve()
      transaction.onerror = (event) => reject("Error storing data: " + event.target.error)
    })
  }

  // const calculateBusinessDays = (startDate, endDate) => {
  //   let count = 0;
  //   const curDate = new Date(startDate.getTime());
  //   while (curDate <= endDate) {
  //     const dayOfWeek = curDate.getDay();
  //     if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
  //     curDate.setDate(curDate.getDate() + 1);
  //   }
  //   return count;
  // }

  // const calculateTimeliness = (deadline, dateCompleted) => {
  //   if (!dateCompleted) return 'Not Completed';
    
  //   const deadlineDate = new Date(deadline);
  //   const completedDate = new Date(dateCompleted);
    
  //   if (completedDate <= deadlineDate) return 'On Time';
    
  //   const businessDaysLate = calculateBusinessDays(deadlineDate, completedDate) - 1; // Subtract 1 to exclude the deadline day
  //   console.log("business Days", businessDaysLate)
  //   if (businessDaysLate === 1) return '1 day late';
  //   if (businessDaysLate > 1) return `${businessDaysLate} days late`;
  
    
  //   return 'On Time'; // This case shouldn't occur, but it's here for completeness
  // }

  const calculateTimeliness = (timeliness) => {
    
    if(timeliness === 0) return 'On Time'; 
    if(timeliness > 0) return `${timeliness} day${timeliness===1 ? '' : 's'} Early`;
    if(timeliness < 0) return `${Math.abs(timeliness)} day${timeliness===-1 ? '' : 's'} Late`;
  
    return 'N/A';
  }

  const structureData = data => {
    const filteredDepartments = data.map(dep => {
      return {
        ...dep,
        createdAt: new Date(dep.createdAt).toDateString(),
        client: dep.client.name,
        owner: `${dep.owner.firstName} ${dep.owner.lastName}`,
        deadline: new Date(dep.deadline).toDateString(),
        status: `${dep.status.title}`,
        projectType: dep.projectType.title,
        dateCompleted: dep.dateCompleted ? new Date(dep.dateCompleted).toDateString() : 'Not Completed',
        timeliness: calculateTimeliness(dep.timeliness),
        // timeliness: dep.timeliness,
        ageOfReceivable: dep.ageOfReceivable,
        invoiceValue: `GHC ${nf.format(dep.totalCost)}`
      }

      // console.log(filteredDepartments)
    })

    return filteredDepartments
  } 

  let formatUrl = url => url.replace(/http/g, 'https')

  const mergeData = (localData, newData) => {
    const mergedMap = new Map(localData.map(item => [item.id, item]))
    newData.forEach(newItem => {
      const existingItem = mergedMap.get(newItem.id)
      if (!existingItem || new Date(newItem.updatedAt) > new Date(existingItem.updatedAt)) {
        mergedMap.set(newItem.id, newItem)
      }
    })
    return Array.from(mergedMap.values())
  }

  const getDepartments = async (data_url = projectsUrl) => {
    setLoading(true)
    try {
      const localData = await getLocalData();
      const response = await axios.get(data_url, { headers: getHeaders() })
      const { data, next } = response.data

      const structuredNewData = structureData(data)
      const mergedData = mergeData(localData, structuredNewData)
      // console.log("Merged Data", mergedData)
      if (JSON.stringify(localData) !== JSON.stringify(mergedData) || next) {
            console.log('Changes detected, updating data in reports');
            await setLocalData(mergedData);
            setDepartments(mergedData);

            if (next) {
              await getDepartments(formatUrl(next));
            }
        } else {
          console.log('No changes detected, using local data in reports');
          setDepartments(localData);
        }
      
    } catch (err) {
      console.error('Error fetching departments in reports:', err)
    } finally {
      setLoading(false)
    }
  }

  // let projReportMounted = useRef()
  useEffect(() => {

    // if (!projReportMounted.current) {
    //   if(localStorage.getItem('traffic.creativehubonline.com/project-report')!==null && JSON.parse(localStorage.getItem('traffic.creativehubonline.com/project-report')).length){
    //     console.log("checking effect")
    //     getDepartments()
    //   } else {
    //     console.log("checking effect2")
    //     localStorage.setItem('traffic.creativehubonline.com/project-report', JSON.stringify([]))
    //     getDepartments()
    //   }
    //   // localStorage.setItem('traffic.creativehubonline.com/project-report', JSON.stringify([]))
    //   // getDepartments()
    //   projReportMounted.current = true

    // }
    const setup = async () => {
      try {
        await initDB()
        await getDepartments()
      } catch (error) {
        console.error('Setup error:', error)
      }
    }
    setup()
    // eslint-disable-next-line
  }, [])

  const handleDetail = record => {
    props.history.push(`/projects/${record.id}`)
  }

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      filters: [
        {
          text: 'All',
          value: 'All'
        },
        {
          text: 'Prospecting',
          value: 'Prospecting'
        },
        {
          text: 'WIP',
          value: 'WIP'
        },
        {
          text: 'Completed',
          value: 'Completed'
        },
        {
          text: 'Discontinued',
          value: 'Discontinued'
        },
        {
          text: 'Suspended',
          value: 'Suspended'
        }
      ],
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.status.includes(value)
      // fixed: 'left'
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      width: 120
    },
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
      width: 120
    },
    {
      title: 'Timeliness',
      dataIndex: 'timeliness',
      key: 'timeliness',
      width: 120
    },

    // {
    //   title: 'Timeliness2',
    //   dataIndex: 'timeliness2',
    //   key: 'timeliness2',
    //   width: 120
    // },

    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 120,
      sorter: (a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        return dateA - dateB;
      },
      sortDirections: ['descend', 'ascend']
    },

    {
      title: 'Project Type',
      dataIndex: 'projectType',
      key: 'projectType',
      width: 100
      // fixed: 'left'
    },
    {
      title: 'Date Completed',
      dataIndex: 'dateCompleted',
      key: 'dateCompleted',
      width: 120,
      sorter: (a, b) => {
        const dateA = new Date(a.dateCompleted);
        const dateB = new Date(b.dateCompleted);

        return dateA - dateB;
      },
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Project Owner',
      dataIndex: 'owner',
      key: 'owner',
      width: 120
    },
    {
      title: 'Invoice Value (Pretax)',
      dataIndex: 'invoiceValue',
      key: 'invoiceValue',
      width: 120
    },
    {
      title: 'Age of Receivable',
      dataIndex: 'ageOfReceivable',
      key: 'ageOfReceivable',
      width: 120
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        return dateA - dateB;
      },
      sortDirections: ['descend', 'ascend']
      
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 80,

      render: (text, record) => (
        <Space size="middle">
          {/* <span onClick={handleDetail.bind(this, record)}>View</span> */}
          {/* eslint-disable-next-line */}
          <a href="#" onClick={handleDetail.bind(this, record)}>
            View
          </a>
        </Space>
      )
    }
  ]

  const dataHeaders = [
    {
      label: 'Status',
      key: 'status'
    },
    {
      label: 'Client',
      key: 'client'
    },
    {
      label: 'Project Name',
      key: 'name'
    },
    {
      label: 'Timeliness',
      key: 'timeliness'
    },
    // {
    //   label: 'Timeliness2',
    //   key: 'timeliness2'
    // },

    {
      label: 'Deadline',
      key: 'deadline'
    },

    {
      label: 'Project Type',
      key: 'projectType'
    },
    {
      label: 'Date Completed',
      key: 'dateCompleted'
    },
    {
      label: 'Project Owner',
      key: 'owner'
    },
    {
      label: 'Invoice Value (Pretax)',
      key: 'invoiceValue'
    },
    {
      label: 'Age of Receivable',
      key: 'ageOfReceivable'
    }
  ]

  const excelData = []
  const onSelectChange = selectedRowKeys => {
    // eslint-disable-next-line
    selectedRowKeys.map(key => {
      // eslint-disable-next-line
      departments.map(data => {
        if (key === data.id) {
          excelData.push(data)
          setSelectedRowData(excelData)
        }
      })
    })
    console.log('selectedRowKeys changed: ', selectedRowKeys)
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const hasSelected = selectedRowKeys.length > 0

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row">
        <div className="col-lg-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Project Reports</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">
              <div className="utils__title">
                <span>All Project Performance</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                <Button
                  type="primary"
                  // onClick={download}
                  disabled={!hasSelected}
                  loading={loading}
                >
                  <CSVLink
                    data={selectedRowData}
                    headers={dataHeaders}
                    filename={'Project-Report.csv'}
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                </Button>
                <span style={{ marginLeft: 8 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items`
                    : ''}
                </span>
              </div>
              <FilterTable
                dataSource={departments}
                rowSelection={rowSelection}
                columns={columns}
                searchFields={[
                  'name',
                  'client',
                  'owner',
                  'deadline',
                  'invoiceValue',
                  'projectType',
                  'dateCompleted'
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLoader>
  )
}

export default Department
