import { constants } from 'app-constants'

const initialState = {
  exchangeRate: {
    id: -1,
    ghsToUsd: '4.64000',
    ghsToEur: '5.230000'
  },
  reference: null,
  amount: null,
  currency: null,
  id: null,
  openDialog: false,
  paymentRegion: null,
  paymentOptions: {
    gh: 'mobilemoneyghana,card',
    ke: 'card,mpesa',
    ng: 'card,mobilemoney,ussd',
    ci: 'card,mobilemoneyfranco,'
  },
  subaccountId: null
}

const initialRate = {
  id: -1,
  ghsToUsd: '4.64000',
  ghsToEur: '5.230000'
}

export const payments = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_EXCHANGE_RATE:
      return { ...state, exchangeRate: action.payload }

    case constants.SET_PAYMENT:
      return {
        ...state,
        amount: action.payload.amount,
        currency: action.payload.currency,
        reference: action.payload.reference,
        id: action.payload.id
      }

    case constants.REMOVE_PAYMENT:
      return {
        ...state,
        amount: null,
        currency: null,
        reference: null,
        id: null
      }

    case constants.SET_PAYMENT_REGION:
      return {
        ...state,
        paymentRegion: action.payload
      }

    case constants.REMOVE_PAYMENT_REGION:
      return {
        ...state,
        paymentRegion: null
      }

    case constants.OPEN_PAYMENT_DIALOG:
      return {
        ...state,
        openDialog: action.payload
      }

    case constants.SET_SUBACCOUNT:
      return {
        ...state,
        subaccountId: action.payload
      }

    case constants.REMOVE_SUBACCOUNT:
      return {
        ...state,
        subaccountId: null
      }

    default:
      return state
  }
}

export const exchangeRate = (state = initialRate, action) => {
  switch (action.type) {
    case constants.SET_EXCHANGE_RATE:
      return action.payload
    default:
      return state
  }
}
