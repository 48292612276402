import React, { useEffect, useState } from 'react'
import { Space, Button, Breadcrumb } from 'antd'
// import { toast } from 'react-toastify'

import { getHeaders, usersUrl } from '../../../config'
import axios from 'axios'
import FilterTable from 'components/Forms/searchFilter'
import PageLoader from 'components/Layouts/PageLoader'

const Department = props => {
  const [departments, setDepartments] = useState([])
  const [loading, setLoading] = useState(false)
  // const [selectedRowKeys, setSelectedRowKeys] = useState([])
  // const [selectedRowData, setSelectedRowData] = useState([])

  const getDepartments = () => {
    setLoading(true)
    axios
      .get(usersUrl + '/all', { headers: getHeaders() })
      .then(res => {
        setLoading(false)
        const filteredDepartments = res.data.map(dep => {
          return {
            ...dep,
            createdAt: new Date(dep.dateJoined).toDateString(),
            isActive: dep.isActive ? 'Active' : 'Inactive'
          }
        })
        setDepartments(filteredDepartments)
      })
      .catch(err => {
        setLoading(false)
        console.log('error')
      })
  }

  useEffect(() => {
    getDepartments()
  }, [])

  const handleDetail = record => {
    props.history.push(`/users/${record.id}`)
  }

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 120
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 120
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 120
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      width: 150
      // fixed: 'left'
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      width: 120
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, record) => (
        <Space size="middle">
          {/* <span onClick={handleDetail.bind(this, record)}>View</span> */}
          {/* eslint-disable-next-line */}
          <a href="" onClick={handleDetail.bind(this, record)}>
            View
          </a>
        </Space>
      )
    }
  ]

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row">
        <div className="col-lg-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>System Users</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">
              <div className="utils__title">
                <span>System Users</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                <Button
                  type="primary"
                  onClick={() => props.history.push('/users/add-new')}
                >
                  Add New User
                </Button>
                {/*
                <span style={{ marginLeft: 8 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items`
                    : ""}
                </span> */}
              </div>
              {/* <Table
                columns={columns}
                // rowSelection={rowSelection}
                dataSource={departments}
                pagination={true}
                boardered
              /> */}

              <FilterTable
                dataSource={departments}
                columns={columns}
                searchFields={
                  []
                  // ['email', 'firstName', 'lastName']
                }
              />
            </div>
          </div>
        </div>
      </div>
    </PageLoader>
  )
}

export default Department
