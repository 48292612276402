import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Col, Space, Form } from 'antd'
import {
  getHeaders,
  clientsUrl,
  usersUrl,
  successNotification,
  errorNotification,
  // projectsUrl,
  statusUrl,
  projectTypesUrl,
  tasksUrl
} from '../../../config'
// import { toast } from 'react-toastify'
// import BasicInput from 'components/Forms/BasicInput'
import DatePickerInput from 'components/Forms/DatePickerInput'
import SelectInput from 'components/Forms/SelectInput'
// import moment from 'moment'
import PropTypes from 'prop-types'

const Update = props => {
  const [client, setClient] = useState({
    // name: props.payload.name,
    // deadline: props.payload.deadline,
    taskType: props.payload.taskType.id,
    // taskType: props.payload.taskType ? props.payload.taskType.id : '',
    owners: props.payload.owners.map(ow => ow.id),
    id: props.payload.id,
    status: props.payload.status ? props.payload.status.id : '',
    project: props.match.params.projectId,
    dateCompleted: props.payload.dateCompleted
      ? props.payload.dateCompleted
      : new Date().toJSON().slice(0, 10)
  })

  // const [disableForm, setDisableForm] = useState(false)
  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)
  // const [showModal, setShowModal] = useState(false)
  // eslint-disable-next-line
  const [clients, setClients] = useState([])
  // eslint-disable-next-line
  const [users, setUsers] = useState([])
  const [status, setStatus] = useState([])
  // eslint-disable-next-line
  const [types, setTypes] = useState([])

  // const getProject = () => {
  //   axios
  //     .get(projectsUrl + '/' + props.payload.id, {
  //       headers: getHeaders()
  //     })
  //     .then(res => {
  //       setClient(res.data)
  //     })
  //     .catch(err => {
  //       if (err.response) {
  //         setServerErrors(err.response.data)
  //       }
  //       errorNotification('Error adding user, retry.')
  //     })
  // }

  const getUsers = () => {
    axios
      .get(usersUrl + '/all', {
        headers: getHeaders()
      })
      .then(res => {
        setUsers(res.data)
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error getting user, retry.')
      })
  }

  const getClients = () => {
    axios
      .get(clientsUrl, {
        headers: getHeaders()
      })
      .then(res => {
        setClients(res.data.data)
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error getting clients, retry.')
      })
  }

  const getStatus = () => {
    axios
      .get(statusUrl, {
        headers: getHeaders()
      })
      .then(res => {
        setStatus(res.data.data)
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error getting user, retry.')
      })
  }

  const getTypes = () => {
    axios
      .get(projectTypesUrl, {
        headers: getHeaders()
      })
      .then(res => {
        setTypes(res.data.data)
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error getting user, retry.')
      })
  }

  useEffect(() => {
    // setClient(props.payload)
    getClients()
    getUsers()
    getStatus()
    getTypes()
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line
  // const handleInputChange = stateName => event => {
  //   setClient({ ...client, [stateName]: event.target.value })
  // }

  // eslint-disable-next-line
  const handleDateUpdate = stateName => date => {
    setClient({ ...client, [stateName]: date })
  }

  const handleSelect = stateName => value => {
    setClient({
      ...client,
      [stateName]: value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    let status_ = status.find(st => st.id === client.status)
    let data = {
      ...client,
      dateCompleted:
        status_ && String(status_.value).toLowerCase() === 'completed'
          ? client.dateCompleted
          : null
    }

    setLoading(true)
    axios
      .put(tasksUrl + '/' + client.id, data, {
        headers: getHeaders()
      })
      .then(res => {
        console.log(res)
        setLoading(false)
        props.handleCancel()
        successNotification('Task Updated')
        let updatetracker = Math.random().toString();
        localStorage.setItem('trackUpdate', updatetracker);
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error updating task, retry.')
      })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Form layout="vertical" className="p-2rem">
            <div
              className="row"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <div className="col-md-5">
                <SelectInput
                  placeholder="select"
                  label="Choose Task Status"
                  name="status"
                  value={client.status}
                  onChange={handleSelect('status')}
                  optionList={status.map(c => ({
                    label: c.title,
                    value: c.id
                  }))}
                  required={true}
                />
                {serverErrors.status &&
                  serverErrors.status.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}

                {/* <BasicInput
                  label="Task Name"
                  value={client.name}
                  name={'tasj name'}
                  placeholder="task name"
                  handleChange={handleInputChange('name')}
                  required={true}
                />
                {serverErrors.name &&
                  serverErrors.name.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))} */}
              </div>

              <div className="col-md-5">
                <DatePickerInput
                  label="Date of Completion"
                  value={client.dateCompleted}
                  handleChange={handleDateUpdate('dateCompleted')}
                  name="completion date"
                  required={false}
                />
                {
                  console.log(client.dateCompleted,props.payload, status, client)
                }
                {serverErrors.dateCompleted &&
                  serverErrors.dateCompleted.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              {serverErrors.nonFieldErrors &&
                serverErrors.nonFieldErrors.map((err, i) => (
                  <div className="text-danger" key={i}>
                    {err}
                  </div>
                ))}

              <Col md={24}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginBottom: '20px'
                  }}
                >
                  <Space>
                    <Button onClick={() => props.handleCancel()}>Close</Button>
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </Space>
                </div>
              </Col>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

Update.propTypes = {
  payload: PropTypes.object,
  handleCancel: PropTypes.func,
  match: PropTypes.object
}

export default Update
