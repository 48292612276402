import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import LoginForm from './LoginForm'
import mindfullLogo from 'img/mindfull-logo.png'

class LoginPage extends Component {
  handleHome = e => {
    e.preventDefault()
    this.props.history.push('/')
  }

  render() {
    return (
      <div className="auth">
        <div className="auth__bounding-box">
          <div className="auth__top">
            <img
              src={mindfullLogo}
              alt="Mindfull Logo"
              className="bq-logo bq-logo--auth mb-1rem"
              onClick={() => this.props.history.push('/')}
            />
          </div>
          <div>
            <hr />
          </div>
          <div className="auth__caption mb-5rem">
            <h2>Project Manager</h2>
            <small>Login to proceed</small>
          </div>

          <LoginForm />

          {/* <div className="auth__footer mb-1rem">
            <div className="mr-2rem">
              Don't have an account?{' '}
              <Link to="/auth/signup">
                <span className="navbar__link--active">Register Now</span>
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    )
  }
}

LoginPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  })
}

export default withRouter(LoginPage)
