import { constants } from 'app-constants'

const initialProducts = []
const initialCompareProducts = []
const initialPayload = JSON.parse(
  localStorage.getItem('bestquotePayload') || '{}'
)
const minimizeMessenger = false

// TODO: Limit no. of selected policies to 3
// Policy reducers

export const products = (state = initialProducts, action) => {
  switch (action.type) {
    case constants.SET_PRODUCTS:
      return action.payload
    case constants.TOGGLE_PRODUCT:
      return state.map(policy => {
        if (policy.id !== action.payload) {
          return policy
        }
        return {
          ...policy,
          isToggled: !policy.isToggled
        }
      })
    case constants.CLEAR_PRODUCT_TOGGLES:
      return state.map(policy => {
        return {
          ...policy,
          isToggled: false
        }
      })
    default:
      return state
  }
}

export const motorProducts = (state = initialProducts, action) => {
  switch (action.type) {
    case constants.SET_MOTOR_PRODUCTS:
      return action.payload
    default:
      return state
  }
}

export const healthProducts = (state = initialProducts, action) => {
  switch (action.type) {
    case constants.SET_HEALTH_PRODUCTS:
      return action.payload
    default:
      return state
  }
}

export const lifeProducts = (state = initialProducts, action) => {
  switch (action.type) {
    case constants.SET_LIFE_PRODUCTS:
      return action.payload
    default:
      return state
  }
}

export const propertyProducts = (state = initialProducts, action) => {
  switch (action.type) {
    case constants.SET_PROPERTY_PRODUCTS:
      return action.payload
    default:
      return state
  }
}

export const compareProducts = (state = initialCompareProducts, action) => {
  switch (action.type) {
    case constants.SET_COMPARE_PRODUCTS:
      return action.payload

    case constants.CLEAR_COMPARE_PRODUCTS:
      return []
    default:
      return state
  }
}

export const showMessenger = (state = minimizeMessenger, action) => {
  switch (action.type) {
    case constants.TOGGLE_MESSENGER:
      return !state

    default:
      return state
  }
}

export const payload = (state = initialPayload, action) => {
  switch (action.type) {
    case constants.SET_PAYLOAD:
      localStorage.setItem('bestquotePayload', JSON.stringify(action.payload))
      return action.payload

    case constants.CLEAR_PAYLOAD:
      localStorage.removeItem('bestquotePayload')
      return {}
    default:
      return state
  }
}
