import React from 'react'
import { Switch, withRouter, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import PrivateRoute from 'hocs/PrivateRoute'
import DashboardLayout from '../../components/Layouts/DashboardLayout'
import DashboardOverview from '.'
import UserRoutes from './users'
import ClientRoutes from './Clients'
import ProjectRoutes from './projects'
import TaskRoutes from './user-tasks'
import PageNotFound from 'pages/PageNotFound'
import Reports from './reports'

const DashboardRoutes = props => {
  return (
    <DashboardLayout>
      <Switch>
        <PrivateRoute
          exact
          path={props.match.path}
          component={DashboardOverview}
        />
        <PrivateRoute
          path={`${props.match.path}users`}
          component={UserRoutes}
        />
        <PrivateRoute
          path={`${props.match.path}clients`}
          component={ClientRoutes}
        />
        <PrivateRoute
          path={`${props.match.path}projects`}
          component={ProjectRoutes}
        />
        <PrivateRoute path={`${props.match.path}reports`} component={Reports} />
        <PrivateRoute
          path={`${props.match.path}tasks`}
          component={TaskRoutes}
        />
        <Route component={PageNotFound} />
      </Switch>
    </DashboardLayout>
  )
}

DashboardRoutes.propTypes = {
  match: PropTypes.object
}

export default withRouter(DashboardRoutes)
