import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Col, Space, Form, Input } from 'antd'
import {
  getHeaders,
  passwordChangeUrl,
  successNotification,
  errorNotification
} from '../../../config'
// import { toast } from 'react-toastify'
// import BasicInput from 'components/Forms/BasicInput'
// import DatePickerInput from 'components/Forms/DatePickerInput'
// import SelectInput from 'components/Forms/SelectInput'
// import moment from 'moment'
import PropTypes from 'prop-types'

const Update = props => {
  const [client, setClient] = useState({
    newPassword1: '',
    newPassword2: '',
    currentPassword: ''
  })

  // const [disableForm, setDisableForm] = useState(false)
  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)
  // const [showModal, setShowModal] = useState(false)

  const getClient = () => {
    setClient(props.user)
  }

  useEffect(() => {
    getClient()
    // eslint-disable-next-line
  }, [])

  const handleInputChange = stateName => event => {
    setClient({ ...client, [stateName]: event.target.value })
  }

  // const handleDateUpdate = stateName => date => {
  //   setClient({ ...client, [stateName]: date })
  // }

  // eslint-disable-next-line
  const handleSelect = stateName => value => {
    setClient({
      ...client,
      [stateName]: value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    setLoading(true)
    axios
      .post(passwordChangeUrl, client, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        props.handleCancel()
        successNotification('Password Change')
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error changing password, retry.')
      })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Form layout="vertical" className="p-2rem">
            <div className="row">
              <div className="col-md-8 offset-md-2 mt-2">
                <label for="password" style={{ fontWeight: '700' }}>
                  Current Password
                </label>
                <Input.Password
                  className=""
                  placeholder="***********"
                  type="password"
                  value={client.currentPassword}
                  onChange={handleInputChange('currentPassword')}
                  autoComplete="current-password"
                  required
                />
                {serverErrors.currentPassword &&
                  serverErrors.currentPassword.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              <div className="col-md-8 offset-md-2 mt-3">
                <label for="password" style={{ fontWeight: '700' }}>
                  New Password
                </label>
                <Input.Password
                  className=""
                  placeholder="***********"
                  type="password"
                  value={client.newPassword1}
                  onChange={handleInputChange('newPassword1')}
                  autoComplete="new-password"
                  required
                />
                {serverErrors.newPassword1 &&
                  serverErrors.newPassword1.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              <div className="col-md-8 offset-md-2  mt-3">
                <label for="password" style={{ fontWeight: '700' }}>
                  Confirm New Password
                </label>
                <Input.Password
                  className=""
                  placeholder="***********"
                  type="password"
                  value={client.newPassword2}
                  onChange={handleInputChange('newPassword2')}
                  autoComplete="confirm-new-password"
                  required
                />

                {serverErrors.newPassword2 &&
                  serverErrors.newPassword2.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              {serverErrors.nonFieldErrors &&
                serverErrors.nonFieldErrors.map((err, i) => (
                  <div className="text-danger" key={i}>
                    {err}
                  </div>
                ))}

              <Col md={24}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginBottom: '20px'
                  }}
                  className="mt-3"
                >
                  <Space>
                    <Button onClick={() => props.handleCancel()}>Close</Button>
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </Space>
                </div>
              </Col>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

Update.propTypes = {
  user: PropTypes.object,
  handleCancel: PropTypes.object
}

export default Update
