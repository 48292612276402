import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Col, Space, Form } from 'antd'
import {
  getHeaders,
  errorNotification,
  successNotification,
  paymentsUrl
} from '../../../config'
// import { toast } from 'react-toastify'
import BasicInput from 'components/Forms/BasicInput'
import DatePickerInput from 'components/Forms/DatePickerInput'
// import SelectInput from 'components/Forms/SelectInput'
// import moment from 'moment'
import PropTypes from 'prop-types'

const PaymentForm = props => {
  const [contact, setContact] = useState({
    project: props.project,
    note: '',
    amount: '',
    dateReceived: new Date()
  })

  // const [disableForm, setDisableForm] = useState(false)
  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)
  // const [showModal, setShowModal] = useState(false)

  const getClient = () => {
    // setContact(props.user)
  }

  useEffect(() => {
    getClient()
    // eslint-disable-next-line
  }, [])

  const handleInputChange = stateName => event => {
    setContact({ ...contact, [stateName]: event.target.value })
  }

  const handleDateUpdate = stateName => date => {
    setContact({ ...contact, [stateName]: date })
  }

  // const handleSelect = stateName => value => {
  //   setClient({
  //     ...client,
  //     [stateName]: value
  //   })
  // }

  const handleSubmit = event => {
    event.preventDefault()

    setLoading(true)
    axios
      .post(`${paymentsUrl}`, contact, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        setContact({
          ...contact,
          note: '',
          amount: ''
        })
        successNotification('Payment Added')
        props.handleCancel()
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error adding payment, kindly retry.')
      })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="mb-1rem">
            <Form layout="vertical" className="p-2rem">
              <div className="row">
                <div className="col-md-5">
                  <BasicInput
                    label="Amount Received"
                    value={contact.amount}
                    name={'amount'}
                    placeholder="amount received"
                    handleChange={handleInputChange('amount')}
                    required={true}
                  />
                  {serverErrors.amount &&
                    serverErrors.amount.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}
                </div>

                <div className="col-md-5 offset-md-2 offset-sm-0 ">
                  <DatePickerInput
                    label="Date Received"
                    value={contact.dateReceived}
                    handleChange={handleDateUpdate('dateReceived')}
                    name="date received"
                    required={true}
                  />
                  {serverErrors.dateReceived &&
                    serverErrors.dateReceived.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}
                </div>

                <div className="col-md-5">
                  <BasicInput
                    label="Payment Notes"
                    value={contact.note}
                    name={'payment note'}
                    placeholder="write a note"
                    handleChange={handleInputChange('note')}
                    required={true}
                  />
                  {serverErrors.note &&
                    serverErrors.note.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}
                </div>

                <div className="col-md-5 offset-md-2 offset-sm-0 " />

                {serverErrors.nonFieldErrors &&
                  serverErrors.nonFieldErrors.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}

                <Col md={24}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Space>
                      <Button onClick={() => props.handleCancel()}>
                        Close
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={loading}
                      >
                        Submit
                      </Button>
                    </Space>
                  </div>
                </Col>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

PaymentForm.propTypes = {
  handleCancel: PropTypes.func,
  project: PropTypes.string
}

export default PaymentForm
