import React, { useEffect, useState, useRef } from 'react'
import { Space, Breadcrumb, Tag } from 'antd'

import { getHeaders, tasksUrl } from '../../../config'
import axios from 'axios'
import FilterTable from 'components/Forms/searchFilter'
import PageLoader from 'components/Layouts/PageLoader'

const Department = props => {
  const [departments, setDepartments] = useState([])
  const [loading, setLoading] = useState(false)


  // const getCurrentUserId = JSON.parse(localStorage.getItem('mindfullAdmin')).user.pk
          

  const structureData = data => {
    const filteredDepartments = data.map(dep => {
      return {
        ...dep,
        createdAt: new Date(dep.createdAt).toDateString(),
        owners: dep.owners,
        deadline: new Date(dep.deadline).toDateString(),
        dateCompleted: dep.dateCompleted
          ? new Date(dep.dateCompleted).toDateString()
          : 'N/A',
        status: dep.status ? `${dep.status.title}` : 'N/A',
        project: dep.project ? dep.project.name : 'N/A',
        task: dep.taskType ? dep.taskType.name : 'N/A',
        client: dep.project ? dep.project.client.name : 'N/A'
      }
    })

    return filteredDepartments
  }

  

  let formatUrl = url => url.replace(/http/g, 'https')
  const getDepartments = (data_url = tasksUrl) => {
    setLoading(true)
    axios
      .get(data_url, { headers: getHeaders() })
      .then(res => {
        if(res.data.count > JSON.parse(localStorage.getItem('traffic.creativehubonline.com/user-tasks')).length || parseFloat(localStorage.getItem('trackUpdate')) !== null){
          if (!res.data.previous){
            localStorage.setItem('traffic.creativehubonline.com/user-tasks', JSON.stringify([]))
          }
          
          let finalData = structureData(res.data.data)
          let existingData = localStorage.getItem('traffic.creativehubonline.com/user-tasks')
            ? JSON.parse(localStorage.getItem('traffic.creativehubonline.com/user-tasks'))
            : []
          let finalll = existingData.concat(finalData)

          // Add Item to local storage
          localStorage.setItem(
            'traffic.creativehubonline.com/user-tasks',
            JSON.stringify(finalll)
          )

          // Display in the table
          setDepartments(finalll)

          console.log(
            'TOTAL',
            JSON.parse(localStorage.getItem('traffic.creativehubonline.com/user-tasks'))
              .length , typeof(JSON.parse(localStorage.getItem('traffic.creativehubonline.com/user-tasks'))),"Used 2nd route", res.data.count
          )

          if (res.data.next) {
            getDepartments(formatUrl(res.data.next))
          } else {
            setDepartments(
              JSON.parse(localStorage.getItem('traffic.creativehubonline.com/user-tasks'))
            )
            
            setLoading(false)
          }
        }else if(JSON.parse(localStorage.getItem('traffic.creativehubonline.com/user-tasks')).length === res.data.count){
          console.log(
            'TOTAL:',
            JSON.parse(localStorage.getItem('traffic.creativehubonline.com/user-tasks'))
              .length , "Adding ", res.data.count
          )

          setDepartments(
            JSON.parse(localStorage.getItem('traffic.creativehubonline.com/user-tasks'))
          )
          setLoading(false)
        }
        
      })
      .catch(err => {
        setLoading(false)
        console.log('error')
      })
  }

  let tasksMounted = useRef()

  useEffect(() => {
    if (!tasksMounted.current) {
      if(localStorage.getItem('traffic.creativehubonline.com/user-tasks')!==null && JSON.parse(localStorage.getItem('traffic.creativehubonline.com/user-tasks')).length){
        getDepartments()
      } else {
        localStorage.setItem('traffic.creativehubonline.com/user-tasks', JSON.stringify([]))
        getDepartments()
      }
      
      tasksMounted.current = true
    }
    // if (!tasksMounted.current) {
    //   if(localStorage.getItem('traffic.creativehubonline.com')!==null && JSON.parse(localStorage.getItem('traffic.creativehubonline.com'))
    //   .length){
    //     console.log("local storage data exists")
    //     getDepartments()
    //   } else {
        
    //       console.log("local storage data not exists")
    //       localStorage.setItem('traffic.creativehubonline.com', JSON.stringify([]))
    //       getDepartments()
          
    //     }
    //     tasksMounted.current = true
    // }
    
    // else{

    // }

    // eslint-disable-next-line
  }, [])


  const handleDetail = record => {
    props.history.push(`/tasks/${record.id}`)
  }

  const columns = [
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
      width: 120
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      width: 120
    },
    {
      title: 'Project Name',
      dataIndex: 'project',
      key: 'project',
      width: 120
    },
    {
      title: 'Date Completed',
      dataIndex: 'dateCompleted',
      key: 'dateCompleted',
      width: 120,
      sorter: (a, b) => {
        const dateA = new Date(a.dateCompleted);
        const dateB = new Date(b.dateCompleted);

        return dateA - dateB;
      },
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Timeliness',
      dataIndex: 'timeliness',
      key: 'timeliness',
      width: 120
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 120,
      sorter: (a, b) => {
        const dateA = new Date(a.deadline);
        const dateB = new Date(b.deadline);

        return dateA - dateB;
      },
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Assigned To',
      dataIndex: 'owners',
      key: 'owners',
      width: 120,
      render: (text, record) =>
        record.owners?.map((ow, i) => (
          <Tag key={i}>{`${ow.firstName} ${ow.lastName}`}</Tag>
        ))
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150
      // fixed: 'left'
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      sorter: (a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        return dateA - dateB;
      },
      sortDirections: ['descend', 'ascend']
    },

    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, record) => (
        <Space size="middle">
          {/* <span onClick={handleDetail.bind(this, record)}>View</span> */}
          {/* eslint-disable-next-line */}
          <a href="" onClick={handleDetail.bind(this, record)}>
            View
          </a>
        </Space>
      )
    }
  ]

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row">
        <div className="col-lg-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>My Assigned Tasks</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">
              <div className="utils__title">
                <span>My Tasks</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                {/* <Button
                  type="primary"
                  onClick={() => props.history.push('/projects/add-new')}
                >
                  Add New Project
                </Button> */}
                {/*
                <span style={{ marginLeft: 8 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items`
                    : ""}
                </span> */}
              </div>
              {/* <Table
                columns={columns}
                // rowSelection={rowSelection}
                dataSource={departments}
                pagination={true}
                boardered
              /> */}
              

              <FilterTable
                dataSource={departments}
                columns={columns}
                searchFields={
                    [
                    'task',
                    'client',
                    'owner',
                    'status',
                    'worker',
                    'project'
                  ]
                }
              />
              {console.log(departments)}
            </div>
          </div>
        </div>
      </div>
    </PageLoader>
  )
}

export default Department
