import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Col, Space, Form } from 'antd'
import {
  getHeaders,
  usersUrl,
  successNotification,
  errorNotification
} from '../../../config'
// import { toast } from 'react-toastify'
import BasicInput from 'components/Forms/BasicInput'
// import DatePickerInput from 'components/Forms/DatePickerInput'
import SelectInput from 'components/Forms/SelectInput'
// import moment from 'moment'
import PropTypes from 'prop-types'

const Update = props => {
  const [client, setClient] = useState({
    email: props.user.email,
    firstName: props.user.firstName,
    lastName: props.user.lastName,
    phoneNumber: props.user.phoneNumber,
    jobTitle: props.user.jobTitle,
    userType: props.user.userType
  })

  // const [disableForm, setDisableForm] = useState(false)
  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)
  // const [showModal, setShowModal] = useState(false)

  const getClient = () => {
    setClient(props.user)
  }

  useEffect(() => {
    getClient()
    // eslint-disable-next-line
  }, [])

  const handleInputChange = stateName => event => {
    setClient({ ...client, [stateName]: event.target.value })
  }

  // const handleDateUpdate = stateName => date => {
  //   setClient({ ...client, [stateName]: date })
  // }

  const handleSelect = stateName => value => {
    setClient({
      ...client,
      [stateName]: value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    setLoading(true)
    axios
      .post(usersUrl + '/' + client.id + '/update-user', client, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        props.handleCancel()
        successNotification('User Updated')
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error updating user, retry.')
      })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Form layout="vertical" className="p-2rem">
            <div className="row">
              <div className="col-md-5">
                <BasicInput
                  label="First Name"
                  value={client.firstName}
                  name={'first name'}
                  placeholder="first name"
                  handleChange={handleInputChange('firstName')}
                  required={true}
                />
                {serverErrors.firstName &&
                  serverErrors.firstName.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              <div className="col-md-5 offset-md-2 offset-sm-0 ">
                <BasicInput
                  label="Last Name"
                  value={client.lastName}
                  name={'last name'}
                  placeholder="last name"
                  handleChange={handleInputChange('lastName')}
                  required={true}
                />
                {serverErrors.lastName &&
                  serverErrors.lastName.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              <div className="col-md-5">
                <BasicInput
                  label="Phone Number"
                  value={client.phoneNumber}
                  name={'phone number'}
                  placeholder="phone number"
                  handleChange={handleInputChange('phoneNumber')}
                  required={true}
                />

                {serverErrors.phoneNumber &&
                  serverErrors.phoneNumber.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              <div className="col-md-5 offset-md-2 offset-sm-0 ">
                <BasicInput
                  label="Email"
                  value={client.email}
                  name={'email'}
                  placeholder="email"
                  handleChange={handleInputChange('email')}
                  required={false}
                />
                {serverErrors.email &&
                  serverErrors.email.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              <div className="col-md-5">
                <BasicInput
                  label="Job Title"
                  value={client.jobTitle}
                  name={'job title'}
                  placeholder="job title"
                  handleChange={handleInputChange('jobTitle')}
                  required={true}
                />
                {serverErrors.jobTitle &&
                  serverErrors.jobTitle.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>
              <div className="col-md-5 offset-md-2 offset-sm-0 ">
                <SelectInput
                  placeholder="select"
                  label="Choose This User Type"
                  name="user type"
                  value={client.userType}
                  onChange={handleSelect('userType')}
                  optionList={[
                    { label: 'Admin', value: 'ADMIN' },
                    { label: 'Traffic Manager', value: 'TRAFFIC_MANAGER' },
                    { label: 'User', value: 'USER' }
                  ]}
                  required={true}
                />
                {serverErrors.userType &&
                  serverErrors.userType.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              {serverErrors.nonFieldErrors &&
                serverErrors.nonFieldErrors.map((err, i) => (
                  <div className="text-danger" key={i}>
                    {err}
                  </div>
                ))}

              <Col md={24}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginBottom: '20px'
                  }}
                >
                  <Space>
                    <Button onClick={() => props.handleCancel()}>Close</Button>
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </Space>
                </div>
              </Col>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

Update.propTypes = {
  user: PropTypes.object,
  handleCancel: PropTypes.object
}

export default Update
