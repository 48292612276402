import { constants } from 'app-constants'

export const setUser = user => ({
  type: constants.SET_USER,
  payload: user
})
 
export const unsetUser = () => ({
  type: constants.UNSET_USER
})
