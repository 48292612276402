import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Button, Col, Space, Form } from 'antd'
import {
  getHeaders,
  clientsUrl,
  usersUrl,
  successNotification,
  errorNotification,
  projectsUrl,
  statusUrl,
  projectTypesUrl,
  categoriesUrl
} from '../../../config'
// import { toast } from 'react-toastify'
import BasicInput from 'components/Forms/BasicInput'
import DatePickerInput from 'components/Forms/DatePickerInput'
import SelectInput from 'components/Forms/SelectInput'
// import moment from 'moment'
import PropTypes from 'prop-types'

const Update = props => {
  const [client, setClient] = useState({
    name: props.payload.name,
    description: props.payload.description,
    deadline: props.payload.deadline,
    totalCost: props.payload.totalCost,
    startAt: props.payload.startAt,
    // status: props.payload.sta,
    owner: props.payload.owner.id,
    client: props.payload.client.id,
    id: props.payload.id,
    status: props.payload.status ? props.payload.status.id : '',
    projectType: props.payload.projectType ? props.payload.projectType.id : '',
    paymentDeadline: props.payload.paymentDeadline
      ? props.payload.paymentDeadline
      : null,
    serviceType: props.payload.serviceType ? props.payload.serviceType.id : '',
    // certificatesProvided: props.payload.certificatesProvided,
    recurringBill: props.payload.recurringBill,
    retainerAccount: props.payload.retainerAccount,
    dateCompleted: props.payload.dateCompleted
      ? props.payload.dateCompleted
      : null
  })

  // const [disableForm, setDisableForm] = useState(false)
  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)
  // const [showModal, setShowModal] = useState(false)
  const [clients, setClients] = useState([])
  const [categories, setCategories] = useState([])
  const [users, setUsers] = useState([])
  const [status, setStatus] = useState([])
  const [types, setTypes] = useState([])
  const [showPaymentDeadline, setShowPaymentDeadline] = useState(false)
  // const [showDeadline, setShowDeadline] = useState(false)

  // const getProject = () => {
  //   axios
  //     .get(projectsUrl + '/' + props.payload.id, {
  //       headers: getHeaders()
  //     })
  //     .then(res => {
  //       setClient(res.data)
  //     })
  //     .catch(err => {
  //       if (err.response) {
  //         setServerErrors(err.response.data)
  //       }
  //       errorNotification('Error adding user, retry.')
  //     })
  // }

  const getUsers = () => {
    axios
      .get(usersUrl + '/all', {
        headers: getHeaders()
      })
      .then(res => {
        setUsers(res.data)
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error getting user, retry.')
      })
  }

  const getClients = () => {
    axios
      .get(clientsUrl, {
        headers: getHeaders()
      })
      .then(res => {
        setClients(res.data.data)
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error getting clients, retry.')
      })
  }

  const getCategories = () => {
    axios
      .get(categoriesUrl, {
        headers: getHeaders()
      })
      .then(res => {
        setCategories(res.data.data)
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error getting services, retry.')
      })
  }

  const getStatus = () => {
    axios
      .get(statusUrl, {
        headers: getHeaders()
      })
      .then(res => {
        setStatus(res.data.data)
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error getting user, retry.')
      })
  }

  const getTypes = () => {
    axios
      .get(projectTypesUrl, {
        headers: getHeaders()
      })
      .then(res => {
        let projTypes = res.data.data.sort((a, b) =>
          a.title.localeCompare(b.title)
        )
        setTypes(projTypes)
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error getting user, retry.')
      })
  }
  let projectMounted = useRef()

  useEffect(() => {
    // setClient(props.payload)
    if (!projectMounted.current) {
      getClients()
      getUsers()
      getStatus()
      getTypes()
      getCategories()

      // let status_ = status.find(st => st.id === props.payload.status.id)
      // if (status_ && String(status_.value).toLowerCase() === 'completed') {
      //   setShowDeadline(!showDeadline)
      // }

      projectMounted.current = true
    } else {
      // let status_ = status.find(st => st.id === client.status)
      // if (status_ && String(status_.value).toLowerCase() === 'completed') {
      //   setShowDeadline(!showDeadline)
      // }
    }
    // eslint-disable-next-line
  }, [client])

  const handleInputChange = stateName => event => {
    setClient({ ...client, [stateName]: event.target.value })
  }

  const handleDateUpdate = stateName => date => {
    setClient({ ...client, [stateName]: date })
  }

  const handleSelect = stateName => value => {
    setClient({
      ...client,
      [stateName]: value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    let status_ = status.find(st => st.id === client.status)
    let data = {
      ...client,
      paymentDeadline: !showPaymentDeadline ? null : client.paymentDeadline,
      dateCompleted:
        status_ && String(status_.value).toLowerCase() === 'completed'
          ? client.dateCompleted
          : null
    }

    setLoading(true)
    axios
      .put(projectsUrl + '/' + client.id, data, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        props.handleCancel()
        successNotification('User Updated')
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error updating user, retry.')
      })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Form layout="vertical" className="p-2rem">
            <div
              className="row"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <div className="col-md-5">
                <BasicInput
                  label="Project Name"
                  value={client.name}
                  name={'project name'}
                  placeholder="project name"
                  handleChange={handleInputChange('name')}
                  required={true}
                />
                {serverErrors.name &&
                  serverErrors.name.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              <div className="col-md-5">
                <SelectInput
                  placeholder="select"
                  label="Choose Project Category"
                  name="project category"
                  value={client.serviceType}
                  onChange={handleSelect('serviceType')}
                  optionList={categories.map(c => ({
                    label: c.title,
                    value: c.id
                  }))}
                  required={true}
                />
                {serverErrors.serviceType &&
                  serverErrors.serviceType.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              <div className="col-md-5">
                <SelectInput
                  placeholder="select"
                  label="Is this a retainer account"
                  name="retainer account"
                  value={client.retainerAccount}
                  onChange={handleSelect('retainerAccount')}
                  optionList={[
                    {
                      label: 'Yes',
                      value: true
                    },
                    {
                      label: 'No',
                      value: false
                    }
                  ]}
                  required={true}
                />
                {serverErrors.retainerAccount &&
                  serverErrors.retainerAccount.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              {!client.retainerAccount ? (
                <div className="col-md-5">
                  <BasicInput
                    label="Project Budget"
                    value={client.totalCost}
                    name={'budget'}
                    placeholder="budget"
                    handleChange={handleInputChange('totalCost')}
                    required={true}
                  />

                  {serverErrors.totalCost &&
                    serverErrors.totalCost.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}
                </div>
              ) : null}

              <div className="col-md-5">
                <DatePickerInput
                  label="Project Start Date"
                  value={client.startAt}
                  handleChange={handleDateUpdate('startAt')}
                  name="start date"
                  required={true}
                />

                {serverErrors.startAt &&
                  serverErrors.startAt.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              <div className="col-md-5">
                <DatePickerInput
                  label="Project Deadline"
                  value={client.deadline}
                  handleChange={handleDateUpdate('deadline')}
                  name="deadline date"
                  required={false}
                />
                {serverErrors.deadline &&
                  serverErrors.deadline.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>
              <div className="col-md-5">
                <SelectInput
                  placeholder="select"
                  label="Choose Project Client"
                  name="client"
                  value={client.client}
                  onChange={handleSelect('client')}
                  optionList={clients.map(c => ({
                    label: c.name,
                    value: c.id
                  }))}
                  required={true}
                />
                {serverErrors.client &&
                  serverErrors.client.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              <div className="col-md-5 ">
                <SelectInput
                  placeholder="select"
                  label="Choose Project Owner"
                  name="project owner"
                  value={client.owner}
                  onChange={handleSelect('owner')}
                  optionList={users.map(c => ({
                    label: `${c.firstName} ${c.lastName}`,
                    value: c.id
                  }))}
                  required={true}
                />
                {serverErrors.owner &&
                  serverErrors.owner.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>
              <div className="col-md-5">
                <SelectInput
                  placeholder="select"
                  label="Choose Project Status"
                  name="status"
                  value={client.status}
                  onChange={handleSelect('status')}
                  optionList={status.map(c => ({
                    label: c.title,
                    value: c.id
                  }))}
                  required={true}
                />
                {serverErrors.status &&
                  serverErrors.status.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              <div className="col-md-5 ">
                <SelectInput
                  placeholder="select"
                  label="Choose Project Type"
                  name="project type"
                  value={client.projectType}
                  onChange={handleSelect('projectType')}
                  optionList={types.map(c => ({
                    label: c.title,
                    value: c.id
                  }))}
                  required={true}
                />
                {serverErrors.projectType &&
                  serverErrors.projectType.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              {!client.retainerAccount ? (
                <div className="col-md-5">
                  <SelectInput
                    placeholder="select"
                    label="Is this a recurring bill?"
                    name="bill"
                    value={client.recurringBill}
                    onChange={handleSelect('recurringBill')}
                    optionList={[
                      {
                        label: 'Yes',
                        value: true
                      },
                      {
                        label: 'No',
                        value: false
                      }
                    ]}
                    required={true}
                  />
                  {serverErrors.recurringBill &&
                    serverErrors.recurringBill.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}
                </div>
              ) : null}

              {!client.retainerAccount ? (
                <div className="col-md-5 ">
                  <SelectInput
                    placeholder="select"
                    label="Choose Custom Payment Deadline?"
                    name="show payment option"
                    value={showPaymentDeadline}
                    onChange={() => {
                      setShowPaymentDeadline(!showPaymentDeadline)
                    }}
                    optionList={[
                      {
                        label: 'Yes',
                        value: true
                      },
                      {
                        label: 'No',
                        value: false
                      }
                    ]}
                    required={true}
                  />
                </div>
              ) : null}

              <div className="col-md-5">
                <DatePickerInput
                  label="Date of Completion"
                  value={client.dateCompleted}
                  handleChange={handleDateUpdate('dateCompleted')}
                  name="completion date"
                  required={false}
                />
                {serverErrors.deadline &&
                  serverErrors.deadline.map((err, i) => (
                    <div className="text-danger" key={i}>
                      {err}
                    </div>
                  ))}
              </div>

              {showPaymentDeadline ? (
                <div className="col-md-5">
                  <>
                    <DatePickerInput
                      label="Deadline for Payment"
                      value={client.paymentDeadline}
                      handleChange={handleDateUpdate('paymentDeadline')}
                      name="payment deadline"
                      required={false}
                    />

                    {serverErrors.paymentDeadline &&
                      serverErrors.paymentDeadline.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </>
                </div>
              ) : null}

              {serverErrors.nonFieldErrors &&
                serverErrors.nonFieldErrors.map((err, i) => (
                  <div className="text-danger" key={i}>
                    {err}
                  </div>
                ))}

              <Col md={24}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginBottom: '20px'
                  }}
                >
                  <Space>
                    <Button onClick={() => props.handleCancel()}>Close</Button>
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </Space>
                </div>
              </Col>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

Update.propTypes = {
  payload: PropTypes.object,
  handleCancel: PropTypes.func
}

export default Update
