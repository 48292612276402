import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Col, Space, Breadcrumb, Form } from 'antd'
import {
  getHeaders,
  usersUrl,
  errorNotification,
  clientsUrl,
  projectsUrl,
  successNotification
} from '../../../config'
// import { toast } from 'react-toastify'
import BasicInput from 'components/Forms/BasicInput'
// import DatePickerInput from 'components/Forms/DatePickerInput'
import SelectInput from 'components/Forms/SelectInput'
import DatePickerInput from 'components/Forms/DatePickerInput'
// import moment from 'moment'

const ClientForm = props => {
  const [department, setDepartment] = useState({
    name: '',
    description: '',
    deadline: new Date(),
    totalCost: '',
    startAt: new Date(),
    status: '',
    owner: '',
    client: ''
    // address: '',
    // dateOfBirth: '' || new Date(),
    // occupation: '',
    // passportNumber: '',
    // driversLicenseId: '',
    // confirmed: false
  })

  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [clients, setClients] = useState([])
  const [users, setUsers] = useState([])

  const getUsers = () => {
    axios
      .get(usersUrl + '/all', {
        headers: getHeaders()
      })
      .then(res => {
        setUsers(res.data)
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error adding user, retry.')
      })
  }

  const getClients = () => {
    axios
      .get(clientsUrl, {
        headers: getHeaders()
      })
      .then(res => {
        setClients(res.data.data)
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error adding user, retry.')
      })
  }

  const handleInputChange = stateName => event => {
    setDepartment({ ...department, [stateName]: event.target.value })
  }

  const handleDateUpdate = stateName => date => {
    setDepartment({ ...department, [stateName]: date })
  }

  const handleSelect = stateName => value => {
    setDepartment({
      ...department,
      [stateName]: value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    // let data = {
    //   ...department,
    // }

    setLoading(true)
    axios
      .post(projectsUrl, department, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        props.history.push('/projects')
        successNotification('New Project Added')
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          setServerErrors(err.response.data)
        }
        successNotification('Error adding project, retry.')
      })
  }

  useEffect(() => {
    getClients()
    getUsers()
    // return () => {
    //   cleanup
    // }
  }, [])

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/projects">Projects</Breadcrumb.Item>
            <Breadcrumb.Item>New Project</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">Add New Project</div>
            <div className="card-body">
              <Form className="p-2rem" layout="vertical">
                <div className="row">
                  <div className="col-md-5">
                    <BasicInput
                      label="Project Name"
                      value={department.name}
                      name={'project name'}
                      placeholder="project name"
                      handleChange={handleInputChange('name')}
                      required={true}
                    />
                    {serverErrors.name &&
                      serverErrors.name.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <BasicInput
                      label="Description"
                      value={department.description}
                      name={'description'}
                      placeholder="description"
                      handleChange={handleInputChange('description')}
                      required={true}
                    />
                    {serverErrors.description &&
                      serverErrors.description.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5">
                    <BasicInput
                      label="Project Budget"
                      value={department.totalCost}
                      name={'budget'}
                      placeholder="budget"
                      handleChange={handleInputChange('totalCost')}
                      required={true}
                    />

                    {serverErrors.totalCost &&
                      serverErrors.totalCost.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <DatePickerInput
                      label="Project Start Date"
                      value={department.startAt}
                      handleChange={handleDateUpdate('startAt')}
                      name="start date"
                      required={true}
                    />

                    {serverErrors.startAt &&
                      serverErrors.startAt.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5">
                    <DatePickerInput
                      label="Project Deadline"
                      value={department.deadline}
                      handleChange={handleDateUpdate('deadline')}
                      name="deadline date"
                      required={true}
                    />
                    {serverErrors.deadline &&
                      serverErrors.deadline.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>
                  <div className="col-md-5 offset-md-2 offset-sm-0">
                    <SelectInput
                      placeholder="select"
                      label="Choose Project Client"
                      name="client"
                      value={department.client}
                      onChange={handleSelect('client')}
                      optionList={clients.map(c => ({
                        label: c.name,
                        value: c.id
                      }))}
                      required={true}
                    />
                    {serverErrors.client &&
                      serverErrors.client.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5 ">
                    <SelectInput
                      placeholder="select"
                      label="Choose Project Owner"
                      name="project owner"
                      value={department.owner}
                      onChange={handleSelect('owner')}
                      optionList={users.map(c => ({
                        label: `${c.firstName} ${c.lastName}`,
                        value: c.id
                      }))}
                      required={true}
                    />
                    {serverErrors.owner &&
                      serverErrors.owner.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>
                  <div className="col-md-5 offset-md-2 offset-sm-0" />

                  {serverErrors.nonFieldErrors &&
                    serverErrors.nonFieldErrors.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}

                  <Col md={24}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginBottom: '20px'
                      }}
                    >
                      <Space>
                        <Button onClick={() => props.history.push('/projects')}>
                          Go Back
                        </Button>
                        <Button
                          type="primary"
                          onClick={handleSubmit}
                          loading={loading}
                        >
                          Submit
                        </Button>
                      </Space>
                    </div>
                  </Col>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientForm
