import React, { useState } from 'react'
import axios from 'axios'
import { Button, Col, Space, Breadcrumb, Form } from 'antd'
import { getHeaders, errorToast, successToast, usersUrl } from '../../../config'
import { toast } from 'react-toastify'
import BasicInput from 'components/Forms/BasicInput'
// import DatePickerInput from 'components/Forms/DatePickerInput'
import SelectInput from 'components/Forms/SelectInput'
// import moment from 'moment'

const ClientForm = props => {
  const [department, setDepartment] = useState({
    email: '',
    firstName: '',
    lastName: '',
    otherNames: '',
    phoneNumber: '',
    jobTitle: '',
    userType: ''
    // address: '',
    // dateOfBirth: '' || new Date(),
    // occupation: '',
    // passportNumber: '',
    // driversLicenseId: '',
    // confirmed: false
  })

  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const handleInputChange = stateName => event => {
    setDepartment({ ...department, [stateName]: event.target.value })
  }
  // eslint-disable-next-line
  const handleDateUpdate = stateName => date => {
    setDepartment({ ...department, [stateName]: date })
  }

  const handleSelect = stateName => value => {
    setDepartment({
      ...department,
      [stateName]: value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    // let data = {
    //   ...department,
    // }

    setLoading(true)
    axios
      .post(usersUrl + '/create-user', department, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        props.history.push('/users')
        successToast(toast, 'New User Added')
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorToast(toast, 'Error adding user, retry.', err, props)
      })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/users">System Users</Breadcrumb.Item>
            <Breadcrumb.Item>New User</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">Add New User</div>
            <div className="card-body">
              <Form className="p-2rem" layout="vertical">
                <div className="row">
                  <div className="col-md-5">
                    <BasicInput
                      label="First Name"
                      value={department.firstName}
                      name={'first name'}
                      placeholder="first name"
                      handleChange={handleInputChange('firstName')}
                      required={true}
                    />
                    {serverErrors.firstName &&
                      serverErrors.firstName.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <BasicInput
                      label="Last Name"
                      value={department.lastName}
                      name={'last name'}
                      placeholder="last name"
                      handleChange={handleInputChange('lastName')}
                      required={true}
                    />
                    {serverErrors.lastName &&
                      serverErrors.lastName.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5">
                    <BasicInput
                      label="Phone Number"
                      value={department.phoneNumber}
                      name={'phone number'}
                      placeholder="phone number"
                      handleChange={handleInputChange('phoneNumber')}
                      required={true}
                    />

                    {serverErrors.phoneNumber &&
                      serverErrors.phoneNumber.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <BasicInput
                      label="Email"
                      value={department.email}
                      name={'email'}
                      placeholder="email"
                      handleChange={handleInputChange('email')}
                      required={true}
                    />
                    {serverErrors.email &&
                      serverErrors.email.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5">
                    <BasicInput
                      label="Job Title"
                      value={department.jobTitle}
                      name={'job title'}
                      placeholder="job title"
                      handleChange={handleInputChange('jobTitle')}
                      required={true}
                    />
                    {serverErrors.jobTitle &&
                      serverErrors.jobTitle.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>
                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <SelectInput
                      placeholder="select"
                      label="Choose This User Type"
                      name="user type"
                      value={department.userType}
                      onChange={handleSelect('userType')}
                      optionList={[
                        { label: 'Admin', value: 'ADMIN' },
                        { label: 'Traffic Manager', value: 'TRAFFIC_MANAGER' },
                        { label: 'User', value: 'USER' }
                      ]}
                      required={true}
                    />
                    {serverErrors.userType &&
                      serverErrors.userType.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  {serverErrors.nonFieldErrors &&
                    serverErrors.nonFieldErrors.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}

                  <Col md={24}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginBottom: '20px'
                      }}
                    >
                      <Space>
                        <Button onClick={() => props.history.push('/users')}>
                          Go Back
                        </Button>
                        <Button
                          type="primary"
                          onClick={handleSubmit}
                          loading={loading}
                        >
                          Submit
                        </Button>
                      </Space>
                    </div>
                  </Col>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientForm
