import React, { Component } from 'react'
import { Table, Input, Button, Space } from 'antd'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

class FilterTable extends Component {
  state = {
    searchText: '',
    searchedColumn: '',
    excelData: [],
    selectedRowKeys: [],
    selectedRowData: []
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => ( 
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex
              })
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100)
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  render() {
    let filteredColumns = this.props.columns.map(i => {
      if (this.props.searchFields.includes(i.dataIndex)) {
        return {
          ...i,
          key: i.dataIndex,
          ...this.getColumnSearchProps(i.dataIndex)
        }
      }
      return {
        ...i,
        key: i.dataIndex
      }
    })

    let filteredData = this.props.dataSource.map(i => {
      return {
        ...i,
        key: i.id
      }
    })

    return (
      <div>
        <Table
          columns={filteredColumns}
          rowSelection={this.props.rowSelection}
          dataSource={filteredData}
          pagination={this.props.pagination}
          boardered
          scroll={{ x: 300, y: 1500 }}
        />

        {filteredData.length && !this.props.pagination ? (
          <div
            className="flex justify-center items-center gap-10 w-full"
            style={{
              padding: '1rem 2rem',
              marginBottom: '2rem',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <span
              onClick={() =>
                this.props.meta.previous
                  ? this.props.getData(this.props.meta.previous)
                  : {}
              }
              className={
                this.props.meta.previous
                  ? 'px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
                  : 'px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-300 bg-white cursor-auto hover:text-gray-300'
              }
              style={
                this.props.meta.previous
                  ? {
                      padding: '1rem 2rem',
                      border: '2rem gray solid',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      backgroundColor: 'gray',
                      marginRight: '2rem',
                      marginTop: '2rem'
                    }
                  : {
                      padding: '1rem 2rem',
                      border: '2rem gray solid',
                      borderRadius: '5px',
                      backgroundColor: 'gray',
                      cursor: 'auto',
                      marginRight: '2rem',
                      marginTop: '2rem'
                    }
              }
            >
              Previous{' '}
            </span>

            <div className="flex items-center justify-between mt-5">
              <div>
                <p className="text-sm text-gray-700">
                  Showing
                  {/* <span className="font-medium ml-1">1</span>
                      <span className="ml-1">to</span> */}
                  <span className="font-medium ml-1">
                    {filteredData.length}
                  </span>
                  <span className="ml-1">out of</span>
                  <span className="font-medium ml-1">
                    {this.props.meta.count}{' '}
                  </span>
                  results
                </p>
              </div>
            </div>

            <span
              onClick={() =>
                this.props.meta.next
                  ? this.props.getData(this.props.meta.next)
                  : {}
              }
              className={
                this.props.meta.next
                  ? 'ml-3 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
                  : 'ml-3 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white text-gray-300 bg-white cursor-auto hover:text-gray-300'
              }
              style={
                this.props.meta.next
                  ? {
                      padding: '1rem 2rem',
                      border: '2rem gray solid',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      backgroundColor: 'gray',
                      marginLeft: '2rem',
                      marginTop: '2rem'
                    }
                  : {
                      padding: '1rem 2rem',
                      border: '2rem gray solid',
                      borderRadius: '5px',
                      backgroundColor: 'gray',
                      cursor: 'auto',
                      marginLeft: '2rem',
                      marginTop: '2rem'
                    }
              }
            >
              Next{' '}
            </span>
          </div>
        ) : null}
      </div>
    )
  }
} 

FilterTable.propTypes = {
  dataSource: PropTypes.array,
  columns: PropTypes.array,
  searchFields: PropTypes.array,
  pagination: PropTypes.bool,
  meta: PropTypes.object,
  getData: PropTypes.func
}

FilterTable.defaultProps = {
  searchFields: [],
  pagination: true,
  meta: {
    previous: null,
    next: null,
    count: 0
  },
  getData: () => {}
}

export default FilterTable
