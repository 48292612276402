import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Col, Space, Breadcrumb, Form } from 'antd'
import {
  getHeaders,
  usersUrl,
  errorNotification,
  projectsUrl,
  successNotification,
  statusUrl,
  tasksUrl
} from '../../../config'
// import { toast } from 'react-toastify'
// import BasicInput from 'components/Forms/BasicInput'
// import DatePickerInput from 'components/Forms/DatePickerInput'
import SelectInput from 'components/Forms/SelectInput'
import DatePickerInput from 'components/Forms/DatePickerInput'
import { ArrowLeftOutlined } from '@ant-design/icons'
// import moment from 'moment'

const ClientForm = props => {
  const [department, setDepartment] = useState({
    taskType: '',
    deadline: new Date(),
    status: '',
    owners: [],
    project: props.match.params.projectId
  })

  const [serverErrors, setServerErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [status, setStatus] = useState([])
  // eslint-disable-next-line
  const [taskTypes, setTaskTypes] = useState([])

  const getUsers = () => {
    axios
      .get(usersUrl + '/all', {
        headers: getHeaders()
      })
      .then(res => {
        setUsers(res.data)
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error getting user, retry.')
      })
  }

  const getStatus = () => {
    axios
      .get(statusUrl, {
        headers: getHeaders()
      })
      .then(res => {
        setStatus(res.data.data)
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error getting status, retry.')
      })
  }

  const getProject = () => {
    axios
      .get(projectsUrl + `/${props.match.params.projectId}`, {
        headers: getHeaders()
      })
      .then(res => {
        let data = res.data
        // get project types
        axios
          .get(`${tasksUrl}/${data.projectType.id}/types`, {
            headers: getHeaders()
          })
          .then(res => {
            console.log(res.data.data)
            setTaskTypes(res.data.data)
          })
          .catch(err => {
            if (err.response) {
              setServerErrors(err.response.data)
            }
            errorNotification('Error getting project types, retry.')
          })
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error getting project, retry.')
      })
  }

  // const handleInputChange = stateName => event => {
  //   setDepartment({ ...department, [stateName]: event.target.value })
  // }

  const handleDateUpdate = stateName => date => {
    setDepartment({ ...department, [stateName]: date })
  }

  const handleSelect = stateName => value => {
    setDepartment({
      ...department,
      [stateName]: value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    let data = {
      ...department
    }

    setLoading(true)
    axios
      .post(tasksUrl, data, {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        props.history.push(`/projects/${props.match.params.projectId}/tasks`)
        successNotification('New Task Added')
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error adding task, retry.')
      })
  }

  useEffect(() => {
    getUsers()
    getStatus()
    getProject()
    // return () => {
    //   cleanup
    // }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/projects">Projects</Breadcrumb.Item>
            <Breadcrumb.Item
              href={`/projects/${props.match.params.projectId}/tasks`}
            >
              Tasks
            </Breadcrumb.Item>
            <Breadcrumb.Item>New Task</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">Add New Task</div>
            <div className="card-body">
              <Form className="p-2rem" layout="vertical">
                <div className="row">
                  <div className="col-md-5">
                    <SelectInput
                      placeholder="select"
                      label="Choose Task Type"
                      name="task type"
                      value={department.taskType}
                      onChange={handleSelect('taskType')}
                      optionList={taskTypes.map(c => ({
                        label: `${c.name}`,
                        value: c.id
                      }))}
                      required={true}
                    />
                    {serverErrors.taskType &&
                      serverErrors.taskType.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5 offset-md-2 offset-sm-0 ">
                    <DatePickerInput
                      label="Task Deadline"
                      value={department.deadline}
                      handleChange={handleDateUpdate('deadline')}
                      name="deadline date"
                      required={true}
                    />
                    {serverErrors.deadline &&
                      serverErrors.deadline.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  <div className="col-md-5 ">
                    <SelectInput
                      placeholder="select"
                      label="Choose Task Owners"
                      name="task owners"
                      mode="multiple"
                      value={department.owners}
                      onChange={handleSelect('owners')}
                      optionList={users.map(c => ({
                        label: `${c.firstName} ${c.lastName}`,
                        value: c.id
                      }))}
                      required={true}
                    />
                    {serverErrors.owners &&
                      serverErrors.owners.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>
                  <div className="col-md-5 offset-md-2 offset-sm-0">
                    <SelectInput
                      placeholder="select"
                      label="Choose Task Status"
                      name="status"
                      value={department.status}
                      onChange={handleSelect('status')}
                      optionList={status.map(c => ({
                        label: c.title,
                        value: c.id
                      }))}
                      required={true}
                    />
                    {serverErrors.status &&
                      serverErrors.status.map((err, i) => (
                        <div className="text-danger" key={i}>
                          {err}
                        </div>
                      ))}
                  </div>

                  {/* <div className="col-md-5 offset-md-2 offset-sm-0" /> */}

                  {serverErrors.nonFieldErrors &&
                    serverErrors.nonFieldErrors.map((err, i) => (
                      <div className="text-danger" key={i}>
                        {err}
                      </div>
                    ))}

                  <Col md={24}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginBottom: '20px'
                      }}
                    >
                      <Space>
                        <Button
                          onClick={() =>
                            props.history.push(
                              `/projects/${props.match.params.projectId}/tasks`
                            )
                          }
                        >
                          <ArrowLeftOutlined /> Project Tasks
                        </Button>
                        <Button
                          type="primary"
                          onClick={handleSubmit}
                          loading={loading}
                        >
                          Submit
                        </Button>
                      </Space>
                    </div>
                  </Col>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientForm
