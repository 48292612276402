import React, { useState, useEffect } from 'react'
import axios from 'axios'
// eslint-disable-next-line
import { Button, Space, Breadcrumb, Tag, Modal } from 'antd'
// eslint-disable-next-line
import {
  getHeaders,
  projectsUrl,
  successNotification,
  errorNotification,
  tasksUrl
} from '../../../config'
// import { toast } from 'react-toastify'
// import BasicInput from 'components/Forms/BasicInput'
// import DatePickerInput from 'components/Forms/DatePickerInput'
// import SelectInput from 'components/Forms/SelectInput'
// import { CheckOutlined, CheckSquareOutlined } from '@ant-design/icons'
import Update from './Update'
import PageLoader from 'components/Layouts/PageLoader'
// import moment from 'moment'
// import DeadlineForm from './DeadlineForm'
// import FilterTable from 'components/Forms/searchFilter'

const Detail = props => {
  const [client, setClient] = useState({
    deadline: '',
    status: '',
    statusId: '',
    owners: [],
    ownerId: '',
    deadlineChanges: ''
  })
  // eslint-disable-next-line
  const [deadlines, setDeadlines] = useState([])

  const [payload, setPayload] = useState({})

  // const [disableForm, setDisableForm] = useState(false)
  // eslint-disable-next-line
  const [serverErrors, setServerErrors] = useState({})
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  // eslint-disable-next-line
  const [showDeadlineModal, setShowDeadlineModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const getClient = () => {
    setLoading(true)
    axios
      .get(tasksUrl + '/' + props.match.params.id + '/get', {
        headers: getHeaders()
      })
      .then(res => {
        setLoading(false)
        let data = res.data.data
        setClient({
          ...data,
          deadline: new Date(data.deadline).toDateString(),
          status: `${data.status ? data.status.title : 'N/A'}`,
          statusId: data.status ? data.status.id : '',
          taskType: data.taskType ? data.taskType.name : 'N/A',
          owners: data.owners,
          createdAt: new Date(data.createdAt).toDateString()
        })

        setPayload(data)

        setDeadlines(
          data.deadlineChanges
            ? data.deadlineChanges.map(dead => {
                return {
                  ...dead,
                  newDate: new Date(dead.newDate).toDateString(),
                  createdAt: new Date(dead.createdAt).toDateString()
                }
              })
            : []
        )
      })
      .catch(err => {
        setLoading(false)
        console.log('error')
      })
  }

  // eslint-disable-next-line
  const changeDeadline = deadline => {
    axios
      .put(
        projectsUrl + '/' + props.match.params.id,
        {
          ...client,
          deadline,
          owner: client.ownerId,
          status: client.statusId,
          projectType: client.projectTypeId
        },
        { headers: getHeaders() }
      )
      .then(res => {
        successNotification('Deadline Changed')
        getClient()
      })
  }

  useEffect(() => {
    getClient()
    // eslint-disable-next-line
  }, [])

  const handleDeleteTask = () => {
    axios
      .delete(tasksUrl + '/' + props.match.params.id + '/admin-delete', {
        headers: getHeaders()
      })
      .then(res => {
        successNotification('tasks Deleted')
        props.history.push(
          '/projects/' + props.match.params.projectId + '/tasks'
        )
      })
      .catch(err => {
        if (err.response) {
          setServerErrors(err.response.data)
        }
        errorNotification('Error deleting task, retry.')
      })
  }

  // eslint-disable-next-line
  const columns = [
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      width: 120
    },
    {
      title: 'New Deadline Date',
      dataIndex: 'newDate',
      key: 'newDate',
      width: 120
    },
    {
      title: 'Timestamp',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120
    }
    // {
    //   title: 'Action',
    //   key: 'action',
    //   fixed: 'right',
    //   width: 100,
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <span
    //         // onClick={handleDetail.bind(this, record)}
    //         style={{ color: 'blue', cursor: 'pointer' }}
    //       >
    //         View
    //       </span>
    //     </Space>
    //   )
    // }
  ]

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/projects`}>Projects</Breadcrumb.Item>
            <Breadcrumb.Item
              href={`/projects/${props.match.params.projectId + '/tasks'}`}
            >
              Projects Details
            </Breadcrumb.Item>
            <Breadcrumb.Item
              href={`/projects/${props.match.params.projectId +
                '/tasks'}/tasks`}
            >
              Project Tasks
            </Breadcrumb.Item>
            <Breadcrumb.Item>Tasks Details</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="card mb-4rem">
                <div className="card-header">Project Details</div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Task Type</b>
                      </div>{' '}
                      {client.taskType}
                    </div>

                    <div className="col-md-5 offset-md-2 mb-2rem">
                      <div>
                        <b>Task Owners</b>
                      </div>
                      {client.owners.map((ow, i) => (
                        <Tag key={i}>{`${ow.firstName} ${ow.lastName}`}</Tag>
                      ))}
                    </div>

                    <div className="col-md-5 mb-2rem">
                      <div>
                        <b>Deadline Date</b>
                      </div>
                      {client.deadline}
                    </div>

                    <div className="col-md-5 offset-md-2 mb-2rem">
                      <div>
                        <b>Task Status</b>
                      </div>
                      {client.status}
                    </div>

                    {/* <div className="col-md-5">
                      <div>
                        <b>Number of Times Deadline has been changed</b>
                      </div>
                      {`${deadlines.length} time${
                        deadlines.length > 1 ? 's' : ''
                      }`}
                    </div>
                    <div className="col-md-5 offset-md-2 mb-2rem" /> */}

                    <div className="col-md-7 offset-md-5">
                      <div>
                        <Space>
                          <Button
                            onClick={() =>
                              props.history.push(
                                `/projects/${props.match.params.projectId +
                                  '/tasks'}/tasks`
                              )
                            }
                          >
                            Go Back
                          </Button>
                          <Button
                            type="primary"
                            onClick={() => {
                              setShowModal(true)
                            }}
                            loading={loading}
                          >
                            Update Task
                          </Button>
                          <Button
                            type="danger"
                            onClick={() => {
                              setShowDeleteModal(true)
                            }}
                            loading={loading}
                          >
                            Delete Task
                          </Button>
                        </Space>
                      </div>
                    </div>

                    <Modal
                      title="Update Task Details"
                      centered
                      visible={showModal}
                      onOk={() => setShowModal(false)}
                      onCancel={() => setShowModal(false)}
                      footer={null}
                      width={1000}
                    >
                      <Update
                        payload={payload}
                        handleCancel={() => {
                          setShowModal(false)
                          getClient()
                        }}
                        match={props.match}
                      />
                    </Modal>
                  </div>
                </div>
              </div>

              <Modal
                title="Do You Really Want to Delete This Task?"
                centered
                visible={showDeleteModal}
                onOk={() => setShowDeleteModal(false)}
                onCancel={() => setShowDeleteModal(false)}
                footer={null}
              >
                <div
                  className=""
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button
                    type="danger"
                    onClick={() => {
                      handleDeleteTask()
                    }}
                    loading={loading}
                  >
                    Delete Task
                  </Button>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </PageLoader>
  )
}

export default Detail
